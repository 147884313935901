import eventManager from './events'
import Constants from '../constants';
import { attributeWhiteList, setAttribute } from '../utils.js';
import { renderEngines } from '../constants.js';

const { externalEventsCodes } = Constants;

let CellsManagerDom = class CellsManagerDom  {
  createComponents(spec) {
    /* istanbul ignore if */
    if (!spec) {
      return;
    }
    return spec.map(element => {
      const newElement = this.createElement(element.tagName);
      newElement.__zone = element.zone;
      return newElement;
    });
  }

  setProps(node, props, render) {
    const { BEFORE_SET_ATTR_TO_NODE, AFTER_SET_ATTR_TO_NODE } = externalEventsCodes;

    if (props && typeof props === 'object') {
      eventManager.emit(BEFORE_SET_ATTR_TO_NODE, node.tagName);

      Object.keys(props).forEach( (key) => {
        if (render === renderEngines.LIT_ELEMENT && attributeWhiteList.includes(key)) {
          setAttribute(node, key, props[key]);
        } else {
          node[key] =  props[key];
        }
      });
      eventManager.emit(AFTER_SET_ATTR_TO_NODE, node);
    }
  }

  setAttrs(node, attrs) {
    if (attrs && typeof attrs === 'object') {

      Object.keys(attrs).forEach( (key) => {
        setAttribute(node, key, attrs[key]);
      });
    }
  }

  createElement(tagName, properties, render) {
    const { BEFORE_CREATE_NODE, AFTER_CREATE_NODE } = externalEventsCodes;

    eventManager.emit(BEFORE_CREATE_NODE, tagName);
    var node = document.createElement(tagName);
    eventManager.emit(AFTER_CREATE_NODE, node);

    /* istanbul ignore else */
    if (properties) {
      this.setProps(node, properties, render);
    }

    return node;
  }
}

export default CellsManagerDom;
