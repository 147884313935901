const _dashToCamelCase = input => {
  return input.toLowerCase().replace(/-(.)/g, function(match, group1) {
    return group1.toUpperCase();
  });
}


export default class VanillaAdapter {
  constructor(componentConnector) {
    this.componentConnector = componentConnector;
  }

  isUnresolved(node) {
    return false;
  }

  isInstance(node) {
    const isCustomElement = node.tagName.includes('-');
    return Boolean(!isCustomElement || customElements.get(node.tagName.toLowerCase()));
  }

  /**
   * Returns true if the event has reached the node that is listening the event
   *
   * @param {Event} event
   */
  isEventAtTarget(event) {
    return event.eventPhase === Event.AT_TARGET;
  }

  dispatchActionFunction(evt, target, method) {
    const payload = evt.detail;

    if (typeof(method) === 'function') {
      method(payload);
    } else {
      target[method](payload);
    }
  }

  dispatchActionProperty(evt, target, property) {
    const data = this._parseActionInEvent(evt, property, target);
    return target[data.property] = data.value;
  }

  processFirstTimeConnections(node, connections) {

    if (!node || !connections) {
      return;
    }

    if (node.__isCellsConnected) {
      return;
    }

    /* istanbul ignore else */
    if (connections.in) {
      for (let action in connections.in) {
        if(connections.in.hasOwnProperty(action)){
          /* istanbul ignore else */
          if (this.componentConnector.manager.get(action)._events[0]) {
            /* istanbul ignore else */
            if (connections.ignoreAttr) {
              connections.ignoreAttr.push(connections.in[action].bind);
            } else {
              connections.ignoreAttr = [connections.in[action].bind];
            }
          }
        }
      }
    }

    node.__isCellsConnected = true;
  }

  _getProperyChangedName(name) {
    let propertyName;
    const EVENT_CHANGED = '-changed';

    if (name.indexOf(EVENT_CHANGED, name.length - EVENT_CHANGED.length) !== -1) {
      propertyName = name.slice(0, - EVENT_CHANGED.length);
      propertyName = _dashToCamelCase(propertyName);
    }

    return propertyName;
  }

  _parseActionInEvent (evt, targetPath, target) {
    // Check if event is because of property changed.
    let propertyName = this._getProperyChangedName(evt.type);
    let value;
    let path;
    if (propertyName && evt.detail && evt.detail.hasOwnProperty('value')) {
      value = evt.detail.value;
      targetPath = targetPath || propertyName;

      if (evt.detail.path) {
        path = evt.detail.path.replace(propertyName, targetPath);
      } else {
        path = targetPath;
      }
    } else {
      path = targetPath;
      value = evt.detail;
    }

    if (target !== undefined && target.is === undefined && typeof(value) === 'object') {
      value = JSON.stringify(value);
    }

    return {
      path: path,
      value: value,
      property: targetPath
    };
  };
}