import eventManager from '../manager/events';
import Constants from '../constants';

const { externalEventsCodes } = Constants;

/**
 * Class to define core private channels behavior
 * @class ActionChannelManager
 */
export default class ActionChannelManager {
  constructor(bridge) {
    this.bridge = bridge;
    this.PageManager = bridge.PageManager;
    this.ChannelManager = bridge.BridgeChannelManager;
    this.TemplateManager = bridge.TemplateManager;
  }

  subscribeAll() {
    this.ChannelManager.getBridgeChannel('config').subscribe(evt => this._configSubscriptor(evt));
    this.ChannelManager.getBridgeChannel('locales').subscribe(evt => this._localesSubscriptor(evt));
    this.ChannelManager.getBridgeChannel('logout').subscribe(evt => this._logoutSubscriptor(evt));
    this.ChannelManager.getBridgeChannel('interceptor_context').subscribe(evt => this._appContextSubscriptor(evt));
  }

  updateProperty(prop, value) {
    if (this.isAllowedProperty(prop)) {
      this.bridge[prop] = value;
      window.AppConfig && (window.AppConfig[prop] = value);
    }
  }

  isAllowedProperty(prop) {
    return typeof this.bridge[prop] !== 'function';
  }

  _configSubscriptor(evt) {
    const { TEMPLATE_REGISTERED } = externalEventsCodes;
    const selected = this.TemplateManager.selected;

    if (evt.detail) {
      for (let prop in evt.detail) {
        if(evt.detail.hasOwnProperty(prop)) {
          this.updateProperty(prop, evt.detail[prop]);
        }
      }

      if (evt.detail.app || evt.detail.pagesPath) {
        this.PageManager.clear();
        this.TemplateManager.removeTemplates(selected, this.bridge.crossContainerId);
        eventManager.once(TEMPLATE_REGISTERED, () => this.TemplateManager.removeTemplate(selected));
      }
    }
  }

  _localesSubscriptor(evt) {
    if (window.I18nMsg && evt.detail && evt.detail.lang) {
      window.I18nMsg.lang = evt.detail.lang;
    }
  }

  _logoutSubscriptor() {
    this.bridge.logout();
  }

  _appContextSubscriptor(evt) {
    const appContext = evt.detail;
    this.bridge.setInterceptorContext(appContext);
  }
};
