// eslint-disable-next-line no-unused-vars
import ManagerDom from './manager/dom';
import ManagerPage from './manager/page';
import ComponentConnector from './component-connector';
import Router from './router';
import Sanitizer from './sanitizer';
import eventManager from './manager/events';
import ImportManager from './manager/import';
import TemplateManager from './manager/template';
import Component from './component';
import BridgeChannelManager from './manager/bridge-channels';
import ActionChannelManager from './manager/action-channels';
import Utils from './utils';
import Constants from './constants';
import ComposerEngineProxy from './composer/composer-engine-proxy';
import Monitoring from './monitoring';
import FeatureFlagCollection from './featureflag/feature-flag-collection';
import FeatureFlagComponent from './featureflag/feature-flag-component';
import PostMessageManager from './manager/post-message';
import ApplicationConfigManager from './manager/application-config';
import ApplicationStateManager from './manager/application-state';

const { dasherize, camelize, findProperty } = Utils;
const { bindingCodes, binding, componentsPath, composerEndpoint, externalEvents, externalEventsCodes, initialTemplate: DEFAULT_INITIAL_TEMPLATE, pagesPath: DEFAULT_PAGES_PATH, prplCodes, prpl, preRenderState, renderEngines, pageTypes } = Constants;

const { DEFAULT: ALWAYS } = binding;
const { DEFAULT: DEFER } = prpl;

let coreInstances = 0;
const globalChannel = {};

// @TODO: Agregar los imports y eliminar los `new this.*`
// @TODO: Revisar los métodos que manipulan templates. Quizás deban ser responsabilidad del TemplateManager

export default class CellsBridge {
  ComponentConnector = null;
  DomManager = null;
  ImportManager = null;
  PageManager = null;
  Router = null;
  TemplateManager = null;
  Sanitizer = null;
  BridgeChannelManager = null;

  /**
	 * Binding Type
	 *
	 * 'always'      => Register all components of all views. Never unregister them.
	 * 'delayed'     => Like 'always' but waits for idle to start the animation.
	 * 'ui'          => Register only ui and cross components of all views. Never
	 *                  unregister them. Datamanagers are only connected when the
	 *                  animation of the current view finishes.
	 * 'currentview' => Register all components of the current view when the
	 *                  animation finishes.
	 *
	 * @type {String}
	 */
  binding = ALWAYS;
  /**
   *
   * @type {String}
   *
   *  none => Don't use bundled pages
   *  defer => Use bundled pages. It renders the page once every component has been loaded.
   *  progressive => Use bundled pages. It renders the page immediately while its component are loading.
   *  hero => Use bundled pages. It renders the page immediately while its component are loading using priorities.
   */
  prplLevel = DEFER;
  /**
	 * Pages Cache
	 *
	 * Saves page definitions into localstorage.
	 *
	 * @type {Boolean}
	 */
  cache = true;
  /**
	 * PubSub Context
	 *
	 * 'global'   => Notifies all components of all bridge instances.
	 * 'local'    => Notifies components created by the current bridge instance.
	 *
	 * @type {String}
	 */
  channel = 'global';
  /**
   * URL Components Path
   *
   * URL path to load components.
   *
   * @type {String}
   */
  componentsPath = componentsPath;
  /**
	 * URL templates Path
	 *
	 * URL path to load templates.
	 *
	 * @type {String}
	 */
  templatesPath = '';
  /**
	 * Cross container node Id
	 *
	 * @type {String}
	 */
  crossContainerId = '__cross';
  /**
	 * Prints debug info
	 *
	 * @type {Boolean}
	 */
  debug = true;
  /**
	 * Proactive Cache.
	 * Loads future pages definition.
	 *
	 * @type {Boolean}
	 */
  preCache = false;
  /**
	 * Proactive Render
	 * Render future pages.
	 *
	 * @type {Boolean}
	 */
  preRender = false;
  /**
	 * Prefix for LocalStorage keys
	 *
	 * @type {String}
	 */
  storagePrefix = '__bridge-';
  /**
	 * Lib version.
	 *
	 * @type {string}
	 */
  version = '3.21.0';
  /**
	 * Max number of views
	 *
	 * Keeps this number of template alive.
	 *
	 * @type {Number}
	 */
  viewLimit = 1000;
  /**
   * The name of the initial template that gets rendered
   *
   * @type {String}
   */
  initialTemplate = DEFAULT_INITIAL_TEMPLATE;
  /**
   *  The node where the template will be rendered
   */
  __mainNodeElement = null;

  /**
	 * Events to expose
	 *
	 *
	 * @type {Array}
	 */
  externalEvents = externalEvents;

  /**
   * The lists of routes that are rendered through a WebComponent.
   *
   * @type {Array}
   */
  pages = [];

  /**
   * The path to the folder that contains the components that renders a route.
   *
   * @type {String}
   */
  pagesPath = DEFAULT_PAGES_PATH;

  /**
   * The list of templates that are remote and need to be fetched from a remote server
   * @type {Array}
   */
  remoteTemplates = [];

  /**
   * If it is true, the Bridge will use the Composer Engine to get the json
   * pages (composer mocks)
   * @type {Boolean}
   */
  useComposerEngine = false;

  /**
   * Json that contains the App definition.
   * This is required by Composer Engine.
   *
   * @type {JSON}
   */
  appJson = '';

  appId = '';
  composerEndpoint = composerEndpoint;
  templatesPath = '';

  monitoring = null;

  logs = false;
  navRequestListener = null;

  constructor(config) {
    const { NAV_REQUEST, ROUTER_BACKSTEP } = externalEventsCodes;

    if (!config || typeof config !== 'object') {
      config = {};
    }

    config.prplLevel = this._normalizePrplLevel(config.prplLevel);
    this.preRenderingPages = {};

    let dependencies = config.dependencies;
    delete config.dependencies;
    Object.assign(this, config);

    this._initDependencies(dependencies);

    if (this.channel === 'global') {
      this.ComponentConnector.manager.channels = globalChannel;
    }

    if (this.useComposerEngine) {
      this._initComposerEngine();
    }

    if (!this.mainNode) {
      console.warn('You should indicate the main node of your app');
    } else {
      this._plugExternalEvents();
    }

    if(config.featureFlag) {
      if(config.featureFlag.subscribe) {
        this.featureFlagCollection.subscribe(config.featureFlag.subscribe)
      }

      if(config.featureFlag.defaultChangedCallback) {
        FeatureFlagComponent.defaultChangedCallback(config.featureFlag.defaultChangedCallback);
      }

      if(config.featureFlag.defaultFeatures) {
        this.featureFlagCollection.update({features: config.featureFlag.defaultFeatures})
      }

      if(config.featureFlag.src) {
        fetch(config.featureFlag.src)
          .then(response => response.json())
          .then(response => this.featureFlagCollection.update(response))
      }
    }
    this.id = coreInstances++;
    this._initCrossComponents();

    if (this.cache) {
      this.PageManager.persistent = true;
    }
    if (this.generateRequestUrl) {
      console.assert(
        typeof this.generateRequestUrl === 'function',
        'generateRequestUrl has to be a function'
      );
      this.PageManager.generateRequestUrl = this.generateRequestUrl;
    }
    if (this.onPageDefinitionNotFound) {
      console.assert(
        typeof this.onPageDefinitionNotFound === 'function',
        'onPageDefinitionNotFound has to be a function'
      );
      this.PageManager.onPageDefinitionNotFound = this.onPageDefinitionNotFound;
    }
    this._sanitizePages();

    if (this.interceptor && typeof(this.interceptor) === 'function') {
      this.Router.channelManager = this.BridgeChannelManager;
      this.Router.interceptor = this.interceptor;
    }

    window.$core = window.$core || [];
    /* istanbul ignore else */
    if (this.debug) {
      window.$core.push(this);
      this.printDebugInfo();
    } else {
      window.$core.push(
        {
          // monitoring
          log: (log) => this.log(log),
          ingest: (spans) => this.ingest(spans),
          createSpan: (data) => this.createSpan(data),
          createUUID: () => this.createUUID(),
          flush: () => this.flush(),

          // bridge
          logout: () => this.logout(),
          subscribeToEvent: (eventName, callback) => this.subscribeToEvent(eventName, callback),
          registerInConnection: (channel, node, callback) => this.registerInConnection(channel, node, callback),
          unsubscribe: (channels, node) => this.unsubscribe(channels, node),
          registerOutConnection: (channelName, node, bindName, options) => this.registerOutConnection(channelName, node, bindName, options),
          publish: (channelName, value, options) => this.publish(channelName, value, options),
          updateSubroute: (subroute) => this.updateSubroute(subroute),
          getCurrentRoute: () => this.getCurrentRoute(),
          navigate: (page, params) => this.navigate(page, params),
          updateApplicationConfig: (config, options) => this.updateApplicationConfig(config, options),
          // feature flag
          updateFeatureFlagCollection: (featureResponse) => this.updateFeatureFlagCollection(featureResponse),
          getFeatureFlagCollection: () => this.getFeatureFlagCollection(),
          // analytics
          trackEvent: (data) => this.trackEvent(data),
          // native
          navigateToNative: (page, params) => this.navigateToNative(page, params),
          backStep: () => this.backStep(),
          // router interceptor
          updateInterceptorContext: (ctx) => this.updateInterceptorContext(ctx),
          resetInterceptorContext: () => this.resetInterceptorContext(),
          getInterceptorContext: () => this.getInterceptorContext(),
          setInterceptorContext: (ctx) => this.setInterceptorContext(ctx)
        }
      );
    }
    window.cells = window.$core[0];

    this.BridgeChannelManager.initAppContextChannel();
    this.BridgeChannelManager.getCancelledBackNavigationChannel();
    this.BridgeChannelManager.getInterceptedNavigationChannel();
    this.ActionChannelManager.subscribeAll();

    // Bridge is ready - execute queued bridge commands & load app config & state.
    this._executePendingBridgeQueue();
    this.ApplicationConfigManager.loadAppConfig();
    this.ApplicationStateManager.loadAppState();

    if (this.logs) {
      this._logBridgeReady();
    }

    // 1. Listen for route changes
    // @TODO: Revisar este binding de un método de otro objeto a otro objeto
    this.Router.handler = () => this.routeHandler();
    this.Router.addRoutes(this.routes);
    this._initSkipNavigations();
    this.Router.start();

    this.navRequestListener = (info) => {
      if (this.Router.hashIsDirty) {
        window.location.hash = "#!";
      }
      let event = info.event;
      let navigationDetail = info.detail;
      let page = navigationDetail.page;
      let params = navigationDetail.params;
      let skipHistory = navigationDetail.skipHistory;
      let cleanUntil = navigationDetail.cleanUntil;
      let replace = navigationDetail.replace || false;
      let p = {};


      if (!page && navigationDetail.paramPage && event.detail) {
        page = event.detail[navigationDetail.paramPage];
      }
      if (event.detail && params) {
        for (var param in params) {
          if (event.detail.hasOwnProperty(param)) {
            p[params[param]] = event.detail[param];
          }
        }
      }

      if (cleanUntil) {
        this.Router.clearStackUntil(cleanUntil);
      }

      this.Router.go(page, p, replace, skipHistory);
      Object.assign(this, config);
    }

    eventManager.on(NAV_REQUEST, this.navRequestListener);

    eventManager.on(ROUTER_BACKSTEP, (evt) => this.handleBack(evt));

    this.PostMessageManager.setupPostMessages();

    this._ccRegister = [];
  }

  _sanitizePages() {
    if (this.pages && !this.pageDefinitions) {
      for (let i=0; i<this.pages.length; i++) {
        this.pages[i] = {
          name: this.pages[i],
          type: pageTypes.STATIC,
          adapter: this.onlyLitElements ? renderEngines.LIT_ELEMENT : undefined,
          hasModules: false
        };
      }
      this.pageDefinitions = this.pages;
    } else if (this.pages && this.pageDefinitions) {
      for (let i=0; i<this.pages.length; i++) {
        let j = this.pageDefinitions.findIndex(p => p.name === this.pages[i]);
        if (j > -1) {
          this.pageDefinitions[j].type = this.pageDefinitions[j].type || pageTypes.STATIC;
          this.pages[i] = this.pageDefinitions[j];
        } else {
          this.pages[i] =  {
            name: this.pages[i],
            type: pageTypes.STATIC,
            adapter: this.onlyLitElements ? renderEngines.LIT_ELEMENT : undefined,
            hasModules: false,
          };
          this.pageDefinitions.push(this.pages[i]);
        }
      }
    }
  }

  updateInterceptorContext(ctx) {
    this.Router.updateInterceptorContext(ctx);
  }

  resetInterceptorContext() {
    this.Router.setInterceptorContext({});
  }

  getInterceptorContext() {
    return this.Router.getInterceptorContext();
  }

  setInterceptorContext(ctx) {
    return this.Router.setInterceptorContext(ctx);
  }

  updateFeatureFlagCollection(featureResponse) {
    this.featureFlagCollection.update(featureResponse);
  }

  getFeatureFlagCollection() {
    return this.featureFlagCollection.collection;
  }

  get loadCellsPage() {
    return window.loadCellsPage;
  }

  /**
   * Performs the go back action. This method is overriden by the CellsNativeBridge
   *
   * @return {Object} the executed navigation, an object with properties:
   *  - from
   *  - to
   */
  goBack() {
    return this.Router.back();
  }

  /**
   * This method is executed when the event router-backstep is fired.
   * It calls the hook method for handling backward navigations and if that method
   * allows the continuation, it does the navegation. Otherwise it will cancel the navigation
   * and publish the response in the channel __bridge_cancelled_back_navigation.
   */
  handleBack() {
    this.goBack();
  }

  /**
   * Execute queued bridge commands due to delayed instance of bridge and
   * premature execution of commands.
   *
   * @method
   * @private
   */
  _executePendingBridgeQueue() {
    if (Array.isArray(window.cellsBridgeQueue)) {
      window.cellsBridgeQueue.forEach(({ command, parameters }) => {
        let queuedCommand = this[command];

        if (!queuedCommand) {
          console.log(`WARNING: Invalid cells bridge command execution: ${command} (QUEUE).`);
          return;
        }

        console.log(`Executing queued command ${command}.`);
        queuedCommand.apply(this, parameters);
      });
      delete window.cellsBridgeQueue;
    }
  }

  _logBridgeReady() {
    const { appId, version, binding, prplLevel, cache, preCache, preRender } = this;
    const message = `cells-bridge::ready`;
    const properties = {
      appId,
      version,
      binding,
      href: window.location.href,
      prplLevel,
      cache,
      preCache,
      preRender
    };
    const log = { message, properties };

    this.logBridge(log);
  }

  _initSkipNavigations() {
    if (this.skipNavigations && this.skipNavigations.length>0) {
      for (let i=0; i<this.skipNavigations.length; i++) {
        this.skipNavigations[i].skipHistory = true;
      }
      this.Router.addSkipNavigations(this.skipNavigations);
    }
  }

  _normalizePrplLevel(prplLevelCode) {
    const { VALUES: prplList } = prpl;
    let normalizedCode;

    if (prplList.indexOf(prplLevelCode) > -1) {
      normalizedCode = prplLevelCode;
    } else {
      normalizedCode = prplList[prplLevelCode] || DEFER;
    }
    return normalizedCode;
  }

  _dispatchEvent(name, payload) {
    const mainNode = this.getMainNode();
    const event = (payload) ? new CustomEvent(name, { detail: payload }) : new CustomEvent(name);

    mainNode.dispatchEvent(event);
  }

  _plugExternalEvents() {
    let len = this.externalEvents.length;
    let mainNode = this.getMainNode();

    if (mainNode) {
      for (let i = 0; i < len; i++) {
        const eventName = this.externalEvents[i];

        eventManager.on(eventName, (data) => {
          this._dispatchEvent(eventName, data);

          if (this.logs) {
            this.monitor(eventName, data);
          }
        });
      }
      this._initEventChannels();
    } else {
      console.warn('The defined main node does not exist');
    }
  }

  /**
   * Send analytics data event to main node.
   * Cells Analytics Collector (Adobe / Target) are listening for main node event's and interept & process them.
   *
   * @param {Object} data Analytics info.
   */
  trackEvent(data) {
    const { TRACK_EVENT } = externalEventsCodes;

    // We just emit a TRACK_EVENT event, modeled as an out connection (we respect normal analytics flow)
    eventManager.emit(TRACK_EVENT, { detail: data });
  }

  monitor(eventName, data) {
    const { LOG_EVENT } = externalEventsCodes;
    const isApplicationLogEvent = eventName === LOG_EVENT;
    const isLoggable = isApplicationLogEvent || this.isBridgeLoggableEvent(eventName);

    if (isLoggable) {
      const log = this.monitoring.buildLog(eventName, data);
      const method = isApplicationLogEvent ? 'log' : 'logBridge';

      this[method](log);
    }
  }

  isBridgeLoggableEvent(eventName) {
    return this.monitoring.isBridgeLoggableEvent(eventName);
  }

  async flush() {
    return this.monitoring.flush();
  }

  log(log) {
    this.hasApplicationLoggingEnabled() && this.monitoring.logApplication(log);
  }

  logBridge(log) {
    this.hasBridgeLoggingEnabled() && this.monitoring.logBridge(log);
  }

  ingest(spans) {
    this.hasApplicationLoggingEnabled() && this.monitoring.ingest(spans);
  }

  createSpan(data) {
    if (!this.hasApplicationLoggingEnabled()) {
      return {
        start() {},
        finish() {}
      };
    }

    return this.monitoring.createSpan(data);
  }

  createUUID() {
    return this.monitoring.createUUID();
  }

  hasBridgeLoggingEnabled() {
    return this.monitoring.hasBridgeLoggingEnabled();
  }

  hasApplicationLoggingEnabled() {
    return this.monitoring.hasApplicationLoggingEnabled();
  }

  _initEventChannels() {
    let mainNode = this.getMainNode();
    this.BridgeChannelManager.initEventChannels(mainNode, this.externalEvents);
    this._addInitialSubscribersToEvents();
  }

  _addInitialSubscribersToEvents() {
    if (this.eventSubscriptions && this.eventSubscriptions.length > 0) {
      this._subscribeToEvents(this.eventSubscriptions);
    }
  }

  _subscribeToEvents(eventSubscriptions) {
    eventSubscriptions.forEach((subscription) => {
      const { event, callback } = subscription;

      this.subscribeToEvent(event, callback);
    });
  }

  /**
   * Initialization of cross components container.
   * Check if cross component container exists. Otherwise, it will be created.
   *
   * @private
   */
  _initCrossComponents() {
    const crossContainerTemplateId = this.TemplateManager.computeTemplateId(this.crossContainerId);
    let crossContainer = this.TemplateManager.get(crossContainerTemplateId);
    const crossContainerElement = document.getElementById(crossContainerTemplateId);

    // no cross container registered on memory
    if (!crossContainer) {
      if (!crossContainerElement) {
        // no html element for cross container, we build it from scratch
        crossContainer = this.TemplateManager.createTemplate(this.crossContainerId, { tagName: 'div' });
        document.body.appendChild(crossContainer.node);
      } else {
        // html element found. we register it
        this.usingDeclarativeCrossContainer = true;
        this.TemplateManager.createTemplate(this.crossContainerId, {node: crossContainerElement});
      }
    }
  }

  _initDependencies(dependencies) {
    dependencies = Object.assign(
      {
        ComponentConnector: ComponentConnector,
        DomManager: ManagerDom,
        ImportManager: ImportManager,
        TemplateManager: TemplateManager,
        PageManager: ManagerPage,
        Router: Router,
        Sanitizer: Sanitizer,
        BridgeChannelManager: BridgeChannelManager,
        ActionChannelManager: ActionChannelManager,
        monitoring: Monitoring,
        featureFlagCollection: FeatureFlagCollection,
        PostMessageManager: PostMessageManager,
        ApplicationConfigManager,
        ApplicationStateManager,
      },
      dependencies
    );
    for (let dependence in dependencies) {
      if (dependencies.hasOwnProperty(dependence)) {
        this[dependence] = new dependencies[dependence](this);
      }
    }
  }

  _initComposerEngine() {
    /* global composerEngine */
    this.composerEngine = new ComposerEngineProxy(this.ComponentConnector.manager, composerEngine);
    this.composerEngine.init();
    this.PageManager.composerEngine = this.composerEngine;
  }

  _createCellsComponent(spec, context) {
    let cmp = new Component(spec, context);
    if(spec.featureFlag) {
      FeatureFlagComponent.extend(cmp, this);
    }
    return cmp;
  }

  createCCComponent(spec) {
    var container = this.TemplateManager.get(this.crossContainerId);
    const id = spec.properties.id;
    const selector = id ? `#${id}` : spec.tagName;
    const node = container.node.querySelector(selector);

    if (!node) {
      var cmp = this._createCellsComponent(spec, this);
      cmp.__parentTemplate = container;
      this.ComponentConnector.registerComponent(cmp);
      this._ccRegister.push(this._getCCData(cmp.node,cmp.connections));
      return cmp;
    } else {
      if(!this._ccRegister.filter((cc)=> cc.node === node).length){
        this._ccRegister.push(this._getCCData(node, spec.connections));
      }
      this.ComponentConnector.progressiveRegisterConnections(node, spec.connections);
    }
  }

  _getCCData(node, connections){
    return {node, connections}
  }

  createUIComponent(spec) {
    const { ALWAYS, DELAYED, UI } = bindingCodes;
    var bindingType = this.binding;
    var cmp = this._createCellsComponent(spec, this);

    if (bindingType === ALWAYS || bindingType === DELAYED || bindingType === UI) {
      this.ComponentConnector.registerComponent(cmp);
    }
    return cmp;
  }

  createDMComponent(spec) {
    const { ALWAYS, DELAYED } = bindingCodes;
    var bindingType = this.binding;
    var cmp = this._createCellsComponent(spec, this);

    if (bindingType === ALWAYS || bindingType === DELAYED) {
      this.ComponentConnector.registerComponent(cmp);
    }
    return cmp;
  }

  createComponentsByType(collection, template) {
    return [].concat(collection['CC'].map(item => this.createCCComponent(item)))
      .concat(collection['UI'].map(item => this.createUIComponent(item)))
      .concat(collection['DM'].map(item => this.createDMComponent(item)))
      .filter(cmp => cmp != undefined)
      .map(cmp => {
        cmp.__parentTemplate = cmp.__parentTemplate || template;
        return cmp
      });
  }

  _insideLayout(zone) {
    return zone != undefined && zone.split('.').length === 2;
  }

  _createComponents(response, template, isPreRendering = false) {
    const { PAGE_READY } = externalEventsCodes;
    const { page } = response;

    var options = this;
    var collection = this.Sanitizer.split(response.components);
    var components = this.createComponentsByType(collection, template);
    var unresolvedComponents = [];
    var templateParents = [];
    const findFeatureFlagProperties = findProperty('featureFlag');

    components
      .map(cmp => findFeatureFlagProperties(cmp.spec.connections))
      .forEach(this.featureFlagCollection.add, this.featureFlagCollection);
    var containedComponents = components.filter(component => this._insideLayout(component.zone));
    components = components.filter(component => !this._insideLayout(component.zone));
    components = components.concat(containedComponents);

    for (var i = 0, l = components.length; i < l; i++) {
      // 9. Sets default attributes
      components[i].setProps();
      components[i].setAttrs();
      var component = components[i];
      var parentName = component.__parentTemplate.name;
      if (!templateParents[parentName]) {
        templateParents[parentName] = this.TemplateManager.get(parentName);
      }
      // 9.1 Append components to template
      templateParents[parentName].append(component);

      const adapter = this.ComponentConnector.adapterResolver.getComponentAdapter(component);
      if (adapter.isUnresolved(component.node)) {
        unresolvedComponents.push(component);
      }
    }

    eventManager.emit(PAGE_READY, { page, components: response.components.map(c => c.tagName).join(', ')});

    switch(this.prplLevel) {
    case prplCodes.NONE:
    case prplCodes.DEFER:
      this.ImportManager.loadComponent(unresolvedComponents, options.componentsPath).then(() => {
        this.selectTemplate(page, isPreRendering);
      });
      break;
    case prplCodes.PROGRESSIVE:
      this.ImportManager.loadComponent(unresolvedComponents, options.componentsPath);
      break;
    case prplCodes.HERO:
      this.selectTemplate(page, isPreRendering);
      this.ImportManager.loadComponentByPriority(unresolvedComponents, options.componentsPath);
      break;
    }
  }

  createPageFromWebComponent(pageDefinition) {
    const {name, hasModules} = pageDefinition;

    const componentName = `${name}-page`;
    let component = this.TemplateManager.getNode(name);

    this.BridgeChannelManager.getPrivate(name);

    if (!component) {
      component = this.TemplateManager.createTemplate(name, {tagName: componentName});
      let adapter = this.ComponentConnector.adapterResolver.getAdapterByName(pageDefinition.adapter);

      if (adapter.isUnresolved(component.node)) {
        if (pageDefinition.adapter===renderEngines.POLYMER) {
          return this.loadPolymerWebComponent(name, hasModules);
        } else {
          if (this.loadCellsPage) { // (loadCellsPage) Guard for compatibilty with Cells CLI<3.2
            return this.loadCellsPage(name);
          }
        }
      }
    }
    return Promise.resolve();
  }

  _isLocalTemplate(templateName) {
    let isLocal = true;
    if (this.composerEndpoint && this.remoteTemplates) {
      isLocal = this.remoteTemplates.indexOf(templateName) === -1;
    }
    return isLocal;
  }

  createTemplate(response, isPreRendering = false) {
    let spec = response;
    let name = response.page;
    let node = this.TemplateManager.getNode(name);
    let useComposerEngineForThisPage = this.composerEngine && !this.PageManager._isLocalComposer(name);
    this.BridgeChannelManager.getPrivate(name);
    const pageDefinition = this.getPageDefinition(name);
    let pageType = pageDefinition.adapter;

    if (!pageType) {
      const components = [].concat(spec.components||[]);
      components.push(spec.template || {});
      const hasLitElements = components.filter(comp => comp.render==='litElement').length>0;
      const hasPolymerElements = components.filter(comp => comp.render==='polymer' || comp.render===undefined).length>0;
      if (hasPolymerElements && !hasLitElements) {
        pageType = renderEngines.POLYMER;
      } else {
        if (!hasPolymerElements && hasLitElements) {
          pageType = renderEngines.LIT_ELEMENT;
        }
      }
    }

    const normalizedInitialBundle = this.initialBundle.map(r => r.replace(/(\.js$|\.json$|-page(\.json|\.html)*)/, ''));
    const isPageInInitialBundle = normalizedInitialBundle.indexOf(name) > -1;

    if (!isPreRendering && this.preRenderingPages[name]) {
      this.preRenderingPages[name] = preRenderState.REQUESTED;
      return;
    }

    // Template is cached
    if (node && !useComposerEngineForThisPage) {
      this.selectTemplate(name, isPreRendering);
    } else {
      // Template not cached and has definition
      if (spec && spec.template) {
        if (this.prplLevel === prplCodes.DEFER && this._isLocalTemplate(name) && !isPageInInitialBundle) {
          if (pageType===renderEngines.POLYMER) {
            //pure polymer page
            this.ImportManager.loadBundleForTemplate(this.componentsPath, name).then(() => {
              this._createTemplateFromSpec(name, spec, isPreRendering);
            });
          } else {
            //pure litElement page
            if (pageType===renderEngines.LIT_ELEMENT) {
              if (this.loadCellsPage) { // (loadCellsPage) Guard for compatibilty with Cells CLI<3.2
                this.loadCellsPage(name).then(() => this._createTemplateFromSpec(name, spec, isPreRendering));
              } else {
                this._createTemplateFromSpec(name, spec, isPreRendering);
              }
            } else {
              // mixed page and litElement bundles
              if (this.loadCellsPage) { // (loadCellsPage) Guard for compatibilty with Cells CLI<3.2
                this.loadCellsPage(`${name}-modules`).then(() => {
                  this.ImportManager.loadBundleForTemplate(this.componentsPath, name).then(() => {
                    this._createTemplateFromSpec(name, spec, isPreRendering);
                  });
                });
              } else {
                this.ImportManager.loadBundleForTemplate(this.componentsPath, name).then(() => {
                  this._createTemplateFromSpec(name, spec, isPreRendering);
                });
              }
            }
          }
        } else {
          this._createTemplateFromSpec(name, spec, isPreRendering);
        }
      }
    }
    this._preRender(response, isPreRendering);
  }

  _preRender(response, isPreRendering) {
    if ((this.preCache === true || this.preRender === true) && response.pages) {
      for (var page in response.pages) {
        if(response.pages.hasOwnProperty(page)) {
          let pageAlreadyLoaded = (page === name || this.TemplateManager.cache[page]);
          if (pageAlreadyLoaded) {
            continue;
          }
          if (this.preRender && isPreRendering === false) {
            this.preRenderingPages[page] = preRenderState.WORKING;
          }
          this.loadTemplate(page).then(templateResponse => {
            if (this.preRender && isPreRendering === false) {
              let doPreRender = true;
              if (templateResponse.page) {
                this._idleCallback(() => this.createTemplate(templateResponse, doPreRender));
              } else {
                console.warn('Missing page. ', templateResponse);
              }
            }
          });
        }
      }
    }
  }

  _createTemplateFromSpec(name, spec, isPreRendering = false) {
    // 4. Creates the new template
    var template = this.TemplateManager.createTemplate(name, spec.template, isPreRendering);

    if (this.prplLevel === prplCodes.PROGRESSIVE) {
      this.selectTemplate(name, isPreRendering);
    }
    this.ComponentConnector.registerComponent(template);
    // Add new routes in router
    if (spec.pages) {
      this.Router.addRoutes(spec.pages);
    }
    // 5. Import it when doesn't exists
    const adapter = this.ComponentConnector.adapterResolver.getComponentAdapter(template);
    if (adapter.isUnresolved(template.node)) {
      switch(this.prplLevel) {
      case prplCodes.NONE:
      case prplCodes.DEFER:
        this.ImportManager.loadComponent(template, this.componentsPath).then(() => {
          this._createComponents(spec, template, isPreRendering);
        });
        break;
      case prplCodes.PROGRESSIVE:
      case prplCodes.HERO:
        this._createComponents(spec, template, isPreRendering);
        break;
      }
    } else {
      this._createComponents(spec, template, isPreRendering);
    }

  }

  /**
	 * Id for template node
	 *
	 * @param  {String} name Template name
	 *
	 * @return {String}
	 */
  computeTemplateId(name) {
    return 'cells-template-' + name.replace(/\./g, '-');
  }

  parse(name, value) {
    name = camelize('parse-' + name);
    return typeof this[name] === 'function' ?
      this[name](value) :
      value;
  }

  printDebugInfo() {
    var getColor = function (option, color) {
      var hexColor = option ?
        color :
        '#b0bec5';
      return `background:${hexColor}; color:#fff; padding:2px 4px; margin-right: 5px;`;
    };
    console.log(
      `%cbridge version: ${this.version} %cbinding: ${this.binding} %cprplLevel: ${this.prplLevel} %ccache: ${this.cache} %cpreCache: ${this.preCache} %cpreRender: ${this.preRender} %clogs: ${this.logs}`,
      getColor(this.version, '#003f8d'),
      getColor(this.binding, '#0065ba'),
      getColor(this.prplLevel, '#008ff2'),
      getColor(this.cache, '#0093e2'),
      getColor(this.preCache, '#00aeeb'),
      getColor(this.preRender, '#41cef8'), //#00aeeb
      getColor(this.logs, '#0025ad') //#00aeeb
    );

    if (this.id > 0) {
      console.log(`%cWARNING: There are ${this.id + 1} simultaneous instances of the Bridge running.`,
        getColor(this.id, '#FF0000'));
    }
  }

  routeHandler() {
    const { PARSE_ROUTE } = externalEventsCodes;
    var route = this.Router.currentRoute;

    eventManager.emit(PARSE_ROUTE, route);

    // 2. Load a new page when route changes
    this._handleRouteLoading(route);

    // 3. Publish URL params to global params.
    for (let param in route.params) {
      let eventData = {
        detail: {
          value: route.params[param]
        },
        type: dasherize(param) + '-changed'
      };

      this.ComponentConnector.manager.get(param).next(eventData);
    }
  }

  /**
   * Determines if given route maps to a component.
   *
   * @param  {Object}  name is the page's name.
   * @return {Object}  pageDefinition:
   *                    {
   *                      name: page's name,
   *                      adapter: name of the adapter (polymer|litElement|vanilla)
   *                      type: static or dinamic,
   *                      hasModules: if it has components in an ES module
   *                    }
   */
  getPageDefinition(name) {
    const defaultPageDefinition = {
      name,
      type: pageTypes.DYNAMIC,
      adapter: renderEngines.POLYMER
    };
    let pageDefinition = defaultPageDefinition;
    const definitions = this.pageDefinitions.filter(Boolean).filter(p => p.name === name);
    if (definitions.length===0) {
      this.pageDefinitions.push(defaultPageDefinition);
    } else {
      pageDefinition = definitions[0];
    }
    return pageDefinition;
  }

  loadTemplate(name, params) {
    const { cache, method, body, headers, app, templatesPath: templates } = this;
    const options = { cache, params, method, body, headers };
    const config = { app, templates };

    return this.PageManager.get(name, options, config);
  }

  // @TODO DRY -> sanitizer lo usa también
  async loadPolymerWebComponent(name, hasModules) {
    const path = Array(2).fill(`${name}-page`).join('/');
    const component = { spec: { path: `${path}.html` } };

    if (hasModules) {
      const pageModules = `${name}-modules`;
      await this.loadCellsPage(pageModules);
    }
    return this.ImportManager.loadComponent(component, this.pagesPath);
  }

  _handleRouteLoading(route) {
    const pageDefinition = this.getPageDefinition(route.name);
    if (pageDefinition.type === pageTypes.STATIC) {
      this.createPageFromWebComponent(pageDefinition).then(() => this.selectPage(route.name, route.params));
    } else {
      this.loadTemplate(route.name, route.params).then(response => this.createTemplate(response));
    }
  }

  registerCurrentTemplate(currentTemplate, previousTemplate) {
    const { TEMPLATE_REGISTERED } = externalEventsCodes;
    const { UI } = bindingCodes;
    var options = this;

    if (previousTemplate && previousTemplate !== currentTemplate) {
      this.unregisterChildren(previousTemplate, options.binding === UI ? 'DM' : null);
    }
    this.registerChildren(currentTemplate, options.binding === UI ? 'DM' : null);

    this._updateChannels(previousTemplate, currentTemplate);
    eventManager.emit(TEMPLATE_REGISTERED, { template: currentTemplate.name });
  }

  // this function MAY BE OVERRIDDEN by native bridge - not required due to internal router updating the context
  _updateChannels(previousTemplate, currentTemplate) {
    if (this.BridgeChannelManager) {
      var oldName = previousTemplate ? previousTemplate.name : undefined;
      const ctx = this.getInterceptorContext();
      const currentRoute = this.getCurrentRoute()
      this.BridgeChannelManager.updateBridgeChannels(oldName, currentTemplate.name, ctx, currentRoute);
    }
  }

  registerChildren(template, type) {
    for (let index = 0; index < template.children.length; index++) {
      const component = template.children[index];
      if ((type && type === component.type) || !type) {
        this.ComponentConnector.registerComponent(component);
      }
    }
  }

  unregisterChildren(template, type) {
    for (let index = 0; index < template.children.length; index++) {
      const component = template.children[index];
      if ((type && type === component.type) || !type) {
        this.ComponentConnector.unregisterComponent(component.node);
      }
    }
  }

  registerInConnection(channelName, node, callback) {
    this.ComponentConnector.addSubscription(channelName, node, callback);
  }

  registerOutConnection(channelName, htmlElement, bindName, extraParameters) {
    this.ComponentConnector.addPublication(channelName, htmlElement, bindName,  extraParameters);
  }

  unsubscribe(channels, node) {
    this.ComponentConnector.unsubscribe(channels, node);
  }

  publish(channelName, value, { sessionStorage } = {}) {
    this.ComponentConnector.publish(channelName, value);

    if (sessionStorage === true) {
      this.ApplicationStateManager.saveAppState(channelName, value);
    }
  }

  updateApplicationConfig(config, { sessionStorage } = {}) {
    const CONFIG_CHANNEL_NAME = '__bridge_ch_config';

    this.publish(CONFIG_CHANNEL_NAME, config);

    if (sessionStorage === true) {
      this.ApplicationConfigManager.saveAppConfig(config);
    }
  }

  updateSubroute(subroute) {
    this.Router.updateSubrouteInBrowser(subroute);
  }

  getCurrentRoute() {
    const { name, params, query, subroute } = this.Router.currentRoute;
    return {  name,
              params,
              query,
              subroute,
              hashPath: this.Router._getHashPath()
            }
  }

  navigate(page, params) {
    this.Router.go(page, params);
  }

  // dummy methods only for native override
  navigateToNative(page, params) {

  }

  backStep() {
    const { ROUTER_BACKSTEP } = externalEventsCodes;

    eventManager.emit(ROUTER_BACKSTEP, {
      event: {},
      detail: {}
    });
  }

  _waitRenderComplete(template) {
    return template.node.updateComplete || Promise.resolve();
  }

  selectPage(name, params) {
    const { TEMPLATE_REGISTERED } = externalEventsCodes;
    const template = this.TemplateManager.get(name);
    const currentTemplate = this.TemplateManager.get(this.TemplateManager.selected);
    const oldTemplateName = currentTemplate ? currentTemplate.name : null;

    if (this.onRender) {
      this.onRender(template.node);
    }

    (async () => {
      await this._waitRenderComplete(template);
      this._handleParams(template.node, params);
      const ctx = this.getInterceptorContext();
      const currentRoute = this.getCurrentRoute();
      this.TemplateManager.select(name, this.BridgeChannelManager, this.binding, ctx, currentRoute);

      if (this.BridgeChannelManager) {
        this.BridgeChannelManager.updateBridgeChannels(oldTemplateName, name, ctx, currentRoute);
      }

      eventManager.emit(TEMPLATE_REGISTERED, { template: name });
    })();
  }

  _handleParams(node, params) {
    const shouldBindParams = node.params && Object.keys(params).length > 0;

    if (shouldBindParams) {
      node.params = params;
    }
  }

  selectTemplate(name, isPreRendering = false) {
    const { TEMPLATE_TRANSITION_END } = externalEventsCodes;
    const { CURRENTVIEW, UI, DELAYED } = bindingCodes;
    let options = this;
    let template = this.TemplateManager.get(name);
    const shouldRender = isPreRendering !== true || this.preRenderingPages[name] === preRenderState.REQUESTED;

    if ((options.binding === CURRENTVIEW || options.binding === UI) && shouldRender) {
      // COMPROBAR 5 VECES
      const currentTemplate = this.TemplateManager.get(this.TemplateManager.selected);
      const onTemplateAnimationFinishes = () => this.registerCurrentTemplate(template, currentTemplate);

      if (template.node.animationCompleteEvent) {
        eventManager.listenToOnce(template.node, template.node.animationCompleteEvent, onTemplateAnimationFinishes);
      } else {
        eventManager.once(TEMPLATE_TRANSITION_END, onTemplateAnimationFinishes);
      }
    }
    if (options.onRender) {
      if (template.fixedChildren.length>0) {
        options.onRender(template.node, template.fixedChildren);
      } else {
        options.onRender(template.node);
      }
    }
    // 10. Shows the template created if it is not pre rendering or
    // if it's a pre-rendered page that has been requested
    if (isPreRendering) {
      delete this.preRenderingPages[name];
    }

    if (shouldRender) {
      const ctx = this.getInterceptorContext();
      const currentRoute = this.getCurrentRoute();
      const animateTemplate = () => this.TemplateManager.select(name, this.BridgeChannelManager, options.binding, ctx, currentRoute);

      if (options.binding === DELAYED) {
        this._idleCallback(animateTemplate);
      } else {
        animateTemplate();
      }
    }
  }

  _idleCallback(fn) {
    this.BridgeChannelManager.getIdleCallbackChannel().subscribe(fn);
  }

  /**
   *
   * It subscribe the main node to an event channel.
   *
   * @param {*} eventName is the name of the event to subscribe
   * @param {*} callback is the function to call when the event channel is activated with a new value
   */
  subscribeToEvent(eventName, callback) {
    if (this.externalEvents.indexOf(eventName)<0) {
      console.warn('Trying to subscribe to a non existing event: ', eventName);
      return;
    }
    if (typeof callback !== 'function') {
      console.warn('You must provide a function callback to subscribe to the event: ', eventName);
      return;
    }
    let mainNode = this.getMainNode();
    this.BridgeChannelManager.subscribeToEvent(mainNode, eventName, callback);
  }

  getMainNode() {
    if (!this.__mainNodeElement) {
      this.__mainNodeElement = document.querySelector('#' + this.mainNode);
    }
    return this.__mainNodeElement;
  }

  _disconnectCrossComponents({inConnections, outConnections}, cleanPrivateChannels) {
    inConnections?.forEach(cnx => {
      this.ComponentConnector.unregisterComponent(cnx.component, cleanPrivateChannels);
    });
    outConnections?.forEach(cnx => {
      this.ComponentConnector.unregisterComponent(cnx.component, cleanPrivateChannels);
    });
  }

  _reconnectCrossComponents({inConnections, outConnections}) {
    inConnections?.forEach(cnx => {
      this.registerInConnection(cnx.channel, cnx.component, cnx.bind);
    });
    outConnections?.forEach(cnx => {
      this.registerOutConnection(cnx.channel, cnx.component, cnx.bind, cnx.options);
    });
  }

  _filterdynamic(connections){
    return connections.filter((crossConnect)=>{
      return this._ccRegister.filter((register)=>
        register.node === crossConnect.component).length ===  0;
    });
  }

  _filterDynamicCrossComponents(crossComponentsConnections){
    crossComponentsConnections.inConnections = this._filterdynamic(crossComponentsConnections.inConnections || []);
    crossComponentsConnections.outConnections = this._filterdynamic(crossComponentsConnections.outConnections || []);
  }

  _cleanDynamicCrossComponents(){
    this._ccRegister.forEach((cross)=>{
      cross.node.parentNode.removeChild(cross.node);
    });

    this._ccRegister = [];
  }

  _resetBridgeChannels() {
    const cleanPrivateChannels = true;
    const crossContainerTemplateId = this.TemplateManager.computeTemplateId(this.crossContainerId);
    const crossComponentsConnections = this.BridgeChannelManager.getCCSubscriptions(crossContainerTemplateId, this.getMainNode().id) || {};

    this._disconnectCrossComponents(crossComponentsConnections, cleanPrivateChannels);
    this.BridgeChannelManager.resetBridgeChannels(this.getMainNode(), cleanPrivateChannels);
    this._filterDynamicCrossComponents(crossComponentsConnections);
    this._cleanDynamicCrossComponents();
    this._reconnectCrossComponents(crossComponentsConnections);
  }

  /**
   *
   * Performs a logout action. It resets all channels, removes templates from DOM
   * and redirects to the initial page
   *
   */
  logout() {
    if (this.TemplateManager.selected === this.initialTemplate) {
      return;
    }

    // clean up current page
    this.BridgeChannelManager.publishPrivatePageStatus(this.TemplateManager.selected, false);

    this._resetBridgeChannels();

    if (!this.usingDeclarativeCrossContainer) {
      this.TemplateManager.removeTemplateChildrens(this.crossContainerId);
    };
    this.TemplateManager.removeTemplates(undefined, this.crossContainerId);

    this.ActionChannelManager.subscribeAll();
    this._addInitialSubscribersToEvents();

    if (this.composerEngine) {
      this.composerEngine.reset();
    }

    this.resetInterceptorContext();

    this.Router.init();
    this.Router.go(this.initialTemplate);
  }
}