// binding

export const bindingCodes = {
  ALWAYS: "always",
  DELAYED: "delayed",
  UI: "ui",
  CURRENTVIEW: "currentview"
};

export const binding = {
  DEFAULT: bindingCodes.ALWAYS,
  VALUES: Object.values(bindingCodes)
};

// external events

export const externalEventsCodes = {
  PAGE_READY: "page-ready",
  PARSE_ROUTE: "parse-route",
  AFTER_PUBLISH: "after-publish",
  NAV_REQUEST: "nav-request",
  BEFORE_SET_ATTR_TO_NODE: "before-set-attr-to-node",
  AFTER_SET_ATTR_TO_NODE: "after-set-attr-to-node",
  BEFORE_CREATE_NODE: "before-create-node",
  AFTER_CREATE_NODE: "after-create-node",
  BEFORE_IMPORT: "before-import",
  AFTER_IMPORT: "after-import",
  PAGE_REQUEST: "page-request",
  PAGE_RESPONSE: "page-response",
  DATA_LOAD: "data-load",
  TEMPLATE_TRANSITION_END: "template-transition-end",
  TRACK_EVENT: "track-event",
  TEMPLATE_REGISTERED: "template-registered",
  ROUTER_BACKSTEP: "router-backstep",
  LOG_EVENT: "log-event"
};

export const externalEvents = Object.values(externalEventsCodes);

// componentsPath

export const componentsPath = "./bower_components/";

// composerEndpoint

export const composerEndpoint = "./composerMocks/";

// deployEndpoint

export const deployEndpoint = "";

// initialTemplate

export const initialTemplate = "login";

// monitoring config

// const mrId = 'web';
const mrId = 'core';
// const nameSpace = 'com.bbva.es.channels';
const nameSpace = 'ether.cells';

export const monitoring = {
  SEMAAS: {
    policy: 'ei_bbva_es',
    mrId,
    nameSpace,
    identifier: 'bridge',
    // consumerId: `${mrId}@${nameSpace}`,
    consumerId: 'core@ether.cells',
    tsecStorageKey: 'tsec'
  },
};

// pagesPath

export const pagesPath = "./pages/";

// prpl

export const prplCodes = {
  NONE: "none",
  DEFER: "defer",
  PROGRESSIVE: "progressive",
  HERO: "hero"
};

export const prpl = {
  DEFAULT: prplCodes.DEFER,
  VALUES: Object.values(prplCodes)
};

export const preRenderState = {
  WORKING: 'working',
  REQUESTED: 'requested'
};

// render engines

export const renderEngines = {
  LIT_ELEMENT: 'litElement',
  POLYMER: 'polymer'
};

//

export const pageTypes = {
  STATIC: 'static',
  DYNAMIC: 'dynamic'
}

// default

export default {
  bindingCodes,
  binding,
  componentsPath,
  composerEndpoint,
  deployEndpoint,
  externalEvents,
  externalEventsCodes,
  initialTemplate,
  monitoring,
  pagesPath,
  prplCodes,
  prpl,
  preRenderState,
  renderEngines,
  pageTypes
};
