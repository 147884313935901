/**
 * @class Route
 */
export default class Route {
  /**
   * @type {RegExp}
   */
  static PARAM = /(?::([^/]+))/g;
  /**
   * @type {RegExp}
   */
  static TRAILING_SLASHES = /\/*$/;
  /**
   * @type {string}
   */
  static _404_PAGE_NAME = '404';

  /**
   * @type {string}
   */
  name = '';
  /**
   *
   * {{[key:string]: string}}
   */
  params = {};
  /**
   * @type {string[]}
   */
  patterns = [''];
  /**
   * @type {RegExp[]}
   */
  regexps;
   /**
   * @type {?string}
   */
  redirectPage = null;
  /**
   * @type {boolean}
   */
  isAccessible = true; // needed to discriminate between 404 and rest of pages with same pattern
  /**
   * @type {boolean}
   */
   isWildcarded = false;
  /**
   *
   * @param {string} name
   * @param {string|string[]} pattern
   */
  constructor(name, pattern) {
    this.name = name;
    this.patterns = typeof(pattern) === 'string' ? [pattern] : pattern;
    this.regexps = this.patterns.map(p => this._getRegExp(p));
  }

  /**
   * @param {string} p string with a regular expression used to match a route
   * @returns {RegExp} sanitized for match route taking into account if the
   *    route is wildcarded
   */
  _getRegExp(p) {
    let urlPattern = p;
    let regex;
    let trailingSlashesReplacement = '/*';
    let regExEnding = '$';

    const indexOfWildcard = urlPattern.indexOf('*');
    if (indexOfWildcard>=0) {
      this.isWildcarded = true;
      urlPattern = urlPattern.substring(0, indexOfWildcard);
      trailingSlashesReplacement = '/+';
      regExEnding = '';
    }
    let regexpStr = urlPattern
            .replace(Route.PARAM, '([^/]+)')
            .replace(Route.TRAILING_SLASHES, trailingSlashesReplacement);
    return new RegExp('^' + regexpStr + regExEnding);
  }

  /**
   * @param {{[key:string]: string}} params
   * @returns {string}
   */
  path(params) {
    params = params || {};
    this.params = {};
    let parts;
    let path = this.patterns[0];
    while ((parts = Route.PARAM.exec(this.patterns[0])) !== null) {
      path = path.replace(parts[0], params[parts[1]]);
      this.params[parts[1]] = params[parts[1]];
    }
    const queryParams = [];
    for (let param in params) {
      if (!this.params.hasOwnProperty(param)) {
        queryParams.push(param + '=' + encodeURIComponent(params[param]));
      }
    }
    if (queryParams.length) {
      path += '?' + queryParams.join('&');
    }
    return path;
  }

  /**
   *
   * @param {string} path
   * @returns {?{
   *      index: number,
   *      parts: string[],
   *      pattern: string,
   *      regex: RegExp
   * }}
   */
  matchPath(path) {
    let match = null;
    this.regexps.forEach((re, i) => {
      let result = path.match(re);
      if (result) {
        match = {
          index: i,
          regex: this.regexps[i],
          pattern: this.patterns[i],
          parts: result
        };
      }
    });
    return match;
  }

  /**
   * @param {string} path
   */
  parsePath(path) {
    let match = this.matchPath(path);
    this.params = {};
    this.subroute = undefined;
    if (match) {
      let i = 1;
      let parts;
      if (match.parts[0] !== match.parts.input) {
        this.subroute = match.parts.input.substring(match.parts[0].length-1);
      }
      while ((parts = Route.PARAM.exec(match.pattern)) !== null) {
        this.params[parts[1]] = this._parseParam(match.parts[i]);
        i++;
      }
    }
  }

  /**
   * @param {*} query
   */
  parseQuery(query) {
    this.query = query;
    for (let queryParam in this.query) {
      if(this.query.hasOwnProperty(queryParam)){
        this.params[queryParam] = this.query[queryParam];
      }
    }
  }

  /**
   * @returns {boolean}
   */
  is404() {
    return this.name === Route._404_PAGE_NAME;
  }

  /**
   * @private
   * @param {string} value
   * @returns {boolean}
   */
  _isNumber(value) {
    return parseInt(value) + '' === value || parseFloat(value) + '' === value;
  }

  /**
   * @private
   * @param {string} param
   * @returns {string|number}
   */
  _parseParam(param) {
    return this._isNumber(param) ? +param : param;
  }

  handler() {
    // Overwrite to make something with the current route
  }

}
