
/**
 * Feature Identifier
 * @typedef {String} CellsFeatureKey
 */

/**
  * Feature black list
  * @typedef {Array<CellsFeatureKey>} CellsFeatureBlackList
  */

/**
 * Feature object
 * @typedef {Object} CellsFeature
 * @property {*} defaultValue  Default value of feature
 * @property {*} value         Current value of feature
 * @property {CellsFeatureKey} key      Feature identifier
 *
 */

/**
 * Feature Collection
 * @typedef {Object.<CellsFeatureKey, CellsFeature>} CellsFeatureCollection
 */

 /**
  * Cells Feature Response
  * @typedef {Object} CellsFeatureResponse
  * @property {CellsFeatureCollection} features
  */

/**
 * Channel name of FeatureFlagCollection. Emit data in this channel when collection of FeatureFlag is updated
 * @constant
 * @type {string}
 */
export const BRIDGE_CHANNEL_KEY = '__bridge_ch_featureflag';

/**
 * Name of CustomElement method use to evaluate value of FeatureFlag
 * @constant
 * @type {string}
 */
export const BRIDGE_BIND_KEY = 'featureFlagChangedCallback';

/**
 * FeatureFlag last value collection by node
 */

 export const lastValue = new Map();

export default class FeatureFlagCollection {

  constructor (bridge) {
    this._featureCollection = {};
    this._connector = bridge.ComponentConnector;
  }

  /**
   * Deep copy of collection of features flags
   * @property
   */
  get collection () {
    return JSON.parse(JSON.stringify(this._featureCollection));
  }

  /**
   * Add new feature flag to collection
   * @param {CellsFeature} featureFlag
   */
  add (featureFlag) {
    this._featureCollection[featureFlag.key] = featureFlag;
  }

  /**
   * Get value of feature if is enabled or null is disabled
   * @method
   * @param {CellsFeature} feature   Feature with identifier
   * @returns {*}               Feature value or null if the feature is disabled
   */
  value ({key, defaultValue, value}) {
    let { _featureCollection } = this;
    if(_featureCollection.hasOwnProperty(key) && _featureCollection[key].hasOwnProperty('value')){
      return _featureCollection[key].value;
    } else if (_featureCollection.hasOwnProperty(key) && _featureCollection[key].hasOwnProperty('defaultValue')) {
      return _featureCollection[key].defaultValue;
    } else if (value !== undefined){
      return value;
    } else {
      return defaultValue;
    }
  }

  /**
   * Extend collection; if feature is registered, overwrite values
   * @method
   * @param {CellsFeatureCollection} featureCollection
   * @returns {FeatureFlagCollection} this
   */
  extend (featureCollection) {
    Object.assign(this._featureCollection, featureCollection);
    return this._publish();
  }

  /**
   * Register collection of features and add default value of each feature
   * @method
   * @param {CellsFeatureCollection} featureCollection
   * @returns {FeatureFlagCollection} this
   */
  register (featureCollection) {
    Object.entries(featureCollection)
      .map(([key, {defaultValue}]) => ({[key]: defaultValue}))
      .forEach(singleFeatureCollection => Object.assign(this._featureCollection, singleFeatureCollection) );

    return this._publish();
  }

  /**
   * @method
   * @param {CellsFeatureResponse} featureResponse Response of feature service
   * @returns {FeatureFlagCollection} this
   */
  update (featureResponse) {
    return this.extend(featureResponse.features);
  }

  subscribe (callback) {
    if (typeof callback === 'function') {
      this._connector.manager.get(BRIDGE_CHANNEL_KEY).subscribe(() => callback(this));
    }

    return this;
  }


  /**
   * @private
   * @returns {FeatureFlagCollection} this
   */
  _publish() {
    const channel = this._connector.manager.get(BRIDGE_CHANNEL_KEY);
    channel.next(new CustomEvent(BRIDGE_CHANNEL_KEY, {detail: this.collection}));
    return this;
  }
}