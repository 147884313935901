import CellsStorage from './storage';

const CONFIG_STORAGE_PREFIX = '__app_';
const CONFIG_STORAGE_KEY = 'state';

let ApplicationStateManager = class ApplicationStateManager {
  constructor({ ComponentConnector }) {
    this.ComponentConnector = ComponentConnector;
    this.storage = this._getAppStateStorage();
  }

  _getAppStateStorage() {  
    return new CellsStorage({
      prefix: CONFIG_STORAGE_PREFIX,
      persistent: false,
    });
  }

  saveAppState(channelName, value) {
    const state = this.storage.getItem(CONFIG_STORAGE_KEY) || {};
  
    state[channelName] = value;
    this.storage.setItem(CONFIG_STORAGE_KEY, state);
  }
    
  loadAppState() {
    const state = this.storage.getItem(CONFIG_STORAGE_KEY);
  
    for (let channel in state) {
      this.ComponentConnector.publish(channel, state[channel]);
    }
  }
}

export default ApplicationStateManager;