import { createChannel } from './state';

/**
 * @typedef {import('rxjs/ReplaySubject')} ReplySubject
 */

/**
 * @typedef { ReplySubject } Channel
 */

export class ChannelManager {
  constructor() {
    this.channels = {}; //*1prototype mixin clone problem
  }

  /**
   * Gets a channel by name.
   *
   * @param  {String} name
   *
   * @return {Channel}
   */
  get(name) {
    var channel = this.channels[name];

    if(channel == null) {
      channel = this.create(name);
    }

    return channel;
  }

  /**
   * Gets a channel by name.
   *
   * @param  {String} name
   *
   * @return {Channel}
   */
  getUnsafe(name) {
    return this.channels[name];
  }

  /**
   * Creates a channel.
   *
   * @param  {String} name Channel name
   *
   * @return {Channel}
   */
  create(name) {
    const channel = createChannel();

    channel.name = name;
    this.channels[name] = channel;
    return channel;
  }

  /**
   * Removes a channel from the collection.
   *
   * @param  {String} name Name of the channel to remove.
   */
  remove(name) {
    delete this.channels[name];
  }


  unregisterAllSubscriptors() {
    this.ComponentConnector.subscriptors.forEach((v) => {
      v.subscriptions.forEach(s => s.subscription.unsubscribe());
    });
  }

  cleanAllChannels() {
    for (let channelName in this.channels) {
      if(this.channels.hasOwnProperty(channelName)){
        this.channels[channelName].clean();
      }
    }
  }

  removeAllChannels() {
    for (let channelName in this.channels) {
      if(this.channels.hasOwnProperty(channelName)){
        delete this.channels[channelName];
      }
    }
  }

  /**
   * Returns true if there is a channel registered with the given name,
   *
   * @param {String} name Channel name,
   *
   * @return {Boolean}
   */
  has(name) {
    return this.channels[name] != null;
  }
};