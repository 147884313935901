export default class ComposerEngineProxy {

  /**
   * Creates an instance of ComposerPageBuilder.

   * @param {ComponentConnector} componentConnector
   */
  constructor(componentConnector, composerEngine) {
    this.componentConnector = componentConnector;
    this.composerEngine = composerEngine;
    this.segments = [];
    this.customGroups = [];
    this.normalizedCustomGroups = [];
  }

  /**
   * Initializes the composerEngine setting the initial parameters
   * and subscribing to the channel that will receive the parameters.
   *
   * @param {Object} initialParams has this structure:
   *  {
   *    segments: [ segmentId1, segmentId2, ..., segmentIdN],
   *    customGroups: [ {name: n1, value: v1}, ..., {name: n2, value: v2}]
   *  }
   *
   */
  init(initialParams = {}) {
    this.initialParams = initialParams;
    this.addParams(this.initialParams);
    this.componentConnector.get(this.getComposerEngineChannelName()).subscribe((evt) => this.handleFeatureFlags(evt));
  }

  /**
   * @returns {String} the name of the channel used to pass the parameters to the composerEngine
   */
  getComposerEngineChannelName() {
    return '__composer_engine';
  }

  /**
   * It processes the new parameters that are placed in the composerEngine's channel
   *
   * @param {Event} evt
   */
  handleFeatureFlags(evt) {
    const params = evt.detail;
    this.addParams(params);
  }

  /**
   * Adds parameters to use with composerEngine
   *
   * @param {Object} params has this structure:
   * {
   *  segments: [ segmentId1, segmentId2, ..., segmentIdN],
   *  customGroups: [ {name: n1, value: v1}, ..., {name: n2, value: v2}]
   * }
   */
  addParams(params) {
    this.addSegments(params.segments);
    this.addCustomGroups(params.customGroups);
  }

  /**
   * Adds the segment ids passed in segmentList.
   * It only adds the new segments.
   *
   * @param {Array<String>} segmentList
   */
  addSegments(segmentList = []) {
    segmentList
      .filter(segmentId => !this.segments.includes(segmentId))
      .forEach(segmentId => this.segments.push(segmentId));
  }

  /**
   * Adds the custom groups passed in customGroupList
   * It only adds the new groups.
   *
   * @param {Array<{name, value}>} customGroupList
   */
  addCustomGroups(customGroupList = []) {
    customGroupList
      .forEach(aCustomGroup => {
        const existingGroup = this.customGroups.find(element => element.name === aCustomGroup.name);
        if (!existingGroup) {
          this.customGroups.push(aCustomGroup);
        } else if (!existingGroup.isConstant) {
          existingGroup.value = aCustomGroup.value;
        }
      });
    this.normalizedCustomGroups = this.normalizeCustomGroups(this.customGroups);
  }

  /**
   * It converts the key/value pair of a customGroup into a String with the format
   * used by Composer Engine
   *
   * @param {Array<{name, value}>} customGroups
   * @returns
   */
  normalizeCustomGroups(customGroups) {
    return customGroups.map(group => this.composerEngine.customGroup(group.name, JSON.stringify(group.value)));
  }

  /**
   * It gets the page using the feature flag parameters
   *
   * @param {String} pageName
   */
  getPage(pageName) {
    return this.composerEngine.getPageForAll(
      pageName,
      this.segments,
      this.normalizedCustomGroups
    );
  }

  /**
   * Clears all parameters and sets the initial ones.
   *
   */
  reset() {
    this.segments = [];
    this.customGroups = [];
    this.init(this.initialParams);
  }
}
