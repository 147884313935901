import CellsStorage from './storage';
import CellsConnector from '../connector';
import CellsSanitizer from '../sanitizer';
import eventManager from './events';
import Constants from '../constants';

const { externalEventsCodes } = Constants;


/**
 * Class that retrieves pages either from the cache or by fetching them using http requests.
 * This class can handle:
 *  - dynamic pages: the one that are defined in json files.
 *  Also, dynamic pages can be localy located o remotely located.
 *  - static pages: the one that are defined in html files
 * 
 * @class CellsManagerPage
 * 
 *
 * The constructor receives a CellsBridge that has all the needed configuration:
 *
 * The property 'templatesPath' indicates the path where the local pages are located.
 * It must be defined only if there are local pages.
 *
 * The property 'composerEndpoint' indicates the url where the local pages are located.
 * This url must have the literals '{id}' and '{platform}'. It must be defined only if there are remote pages.
 *
 * The property 'remoteTemplates' is an array that contains the name of the remote templates
 * (just the name, not the extension).
 * It must be defined only if there are remote pages.
 *
  * The property 'htmlPagesPath' is the path to the folder that contains the html pages.
 *
 * The property 'htmlPages' is an array that contains the name of the static html pages
 * (just the name, not the extension).
 *
 * The property 'appId' is the application's id.
 *
 * The property 'getPlatform' is a function, usually defined in the app.js file, that returns
 * a string indicating the platform that runs the application (for instance: destkop, ios or android).
 */
export default class CellsManagerPage {

  
  /**
   * @param {CellsBridge} bridge
   */
  constructor(bridge) {
    this._initCellsPageManager(bridge);
  }

  /**
   * @param {CellsBridge} bridge
   */
  _initCellsPageManager(bridge) {
    const { htmlPagesPath = './pages/', htmlPages, templatesPath, composerEndpoint, remoteTemplates, appId, getPlatform,
      cache, storagePrefix: prefix,
      composerEngine, TemplateManager } = bridge;
    const storage = new CellsStorage({ prefix, persistent: cache });
    const connector =  new CellsConnector();
    const sanitizer = new CellsSanitizer();

    Object.assign(this, {
      storage,
      htmlPagesPath,
      htmlPages,
      templatesPath,
      composerEndpoint,
      remoteTemplates,
      appId,
      getPlatform,
      cache,
      connector,
      sanitizer,
      composerEngine,
      TemplateManager
    });
  }
  /**
   * @param  {String} page
   */
  _remoteTemplate(page) {
    let replaced = this.composerEndpoint.replace(/{appId}/, this.appId);
    if (this.getPlatform) {
      replaced = replaced.replace(/{platform}/, this.getPlatform());
    }
    replaced = replaced.replace(/{page}/, page);
    return replaced;
  }
  /**
   * @param {String} page
   */
  _localTemplate(page) {
    const endpoint = !this.templatesPath ? this.composerEndpoint : this.templatesPath;

    return `${endpoint}${page}.json`;
  }
  /**
   * @param {String} page
   */
  _isLocalComposer(page) {
    let result = true;
    const hasTemplatePath = this.templatesPath && this.templatesPath.length > 0;
    const hasRemoteTemplates = this.remoteTemplates && this.remoteTemplates.length > 0;
    // legacy case when there was just composerEndpoint and
    // it could be a directory or an url
    const oldWayOnlyCompopserEndpointDefined = this.composerEndpoint && !hasTemplatePath && !hasRemoteTemplates;
    if (oldWayOnlyCompopserEndpointDefined) {
      result = this.composerEndpoint.indexOf('http') !== 0;
    } else {
      if (this.composerEndpoint && this.remoteTemplates) {
        result = this.remoteTemplates.indexOf(page) < 0;
      } else {
        result = true;
      }
    }
    return result;
  }
  /**
   * @param  {String} page
   */
  _renderComposerEndpoint(page) {
    if (this._isLocalComposer(page)) {
      return this._localTemplate(page);
    } else {
      return this._remoteTemplate(page);
    }
  }

  /**
   * Returns the url needed to retrieve a page. It handles theese cases:
   *  - page is dynamic (json file) and remote
   *  - page is dynamic (json file) and local
   *  - page is static (html file)
   *
   * @param {String} page is the name of the page to retrieve
   */
  generateRequestUrl(page) {
    if (this.htmlPages && this.htmlPages.indexOf(page) >= 0) {
      return this.htmlPagesPath + page + '.html';
    } else {
      return this._renderComposerEndpoint(page);
    }
  }

  /**
   * @param {Object} page
   */
  // eslint-disable-next-line no-unused-vars
  onPageDefinitionNotFound(page) {
    // Overwrite to make something when page definition is not found.
  }

  clear() {
    this.storage.clear();
  }

  /**
   * Returns a promise that retrieves a dynamic page (json file).
   * The page may come from the cache or from a http request.
   *
   * @param {String} page name
   * @param {Object} options for http request
   * @param {Object} configuration
   */
  get(page, options, config) {
    const useComposerEngineForThisPage = this.composerEngine && !this._isLocalComposer(page);
    let spec;

    if (useComposerEngineForThisPage) {
      this.TemplateManager.removeTemplate(page);
      return this._getFromComposerEngine(page, options);
    } else {
      spec = this.storage.getItem(page);
      if (this.cache && spec) {
        return Promise.resolve(spec);
      } else {
        return this._getFromConnector(page, options, config);
      }
    }
  }

  /**
   *
   * @param {String} page name
   * @param {Object} options for http request
   *    
  */
  _getFromComposerEngine(page, options) {
    const response = JSON.parse(this.composerEngine.getPage(page));

    return Promise.resolve(
      this._onResponse(page, options, response)
    );
  }
  /**
   *
   * @param {String} page name
   * @param {Object} options for http request
   * @param {Object} config configuration
  */
  _getFromConnector(page, options, config) {
    const { PAGE_REQUEST } = externalEventsCodes;

    options = options || {};
    options.url = this.generateRequestUrl(page, options, config);

    eventManager.emit(PAGE_REQUEST, options);

    return this.connector.getJSON(options)
      .then(response => this._onResponse(page, options, response))
      .catch(async (error) => {
        error = await this._onResponseFail(page);
        console.error(error);
        return error.message;
      });
  }

  /**
   * Returns a promise that retrieves a static page (html file).
   * The page comes from a http request.
   *
   * @param {String} page name
   * @param {Object} options for http request
   */
  getPage(page, options) {
    const { PAGE_REQUEST } = externalEventsCodes;

    options = options || {};
    options.url = this.generateRequestUrl(page);

    eventManager.emit(PAGE_REQUEST, options);

    return this.connector.getHTML(options)
      .then(response => this._onResponseHTML(page, options, response))
      .catch(error => {
        this._onResponseFail(page);
        console.error(error);
        return error.message;
      });
  }

  //getComponentPage(...)
  /**
   * Returns a promise that retrieves a static page (html file).
   * The page comes from a http request.
   *
   * @param {String} page name
   * @param {Object} options for http request
   * @param {Response} response for http request
   */
  _onResponse(page, options, response) {
    const { PAGE_RESPONSE, DATA_LOAD } = externalEventsCodes;

    response.page = page;

    eventManager.emit(PAGE_RESPONSE, response);

    let sanitizedData = this.sanitizer.parse(response);
    // sanitizedData = this.bridge.parse('data', sanitizedData);

    this.storage.setItem(page, sanitizedData);

    eventManager.emit(DATA_LOAD, sanitizedData);

    return sanitizedData;
  }

  /**
   * @param  {String} page name of the page
   * @param  {Object} options 
   * @param  {String} responseText html of the page
   */
  _onResponseHTML(page, options, responseText) {
    const { PAGE_RESPONSE, DATA_LOAD } = externalEventsCodes;

    const response = {
      page: page,
      html: responseText
    };

    eventManager.emit(PAGE_RESPONSE, response);

    const sanitizedData = {
      page: page,
      currentPage: {},
      template: {},
      components: [],
      pages: [],
      html: responseText
    };

    this.storage.setItem(page, sanitizedData);

    eventManager.emit(DATA_LOAD, sanitizedData);

    return sanitizedData;
  }

  _onResponseFail(page) {
    if (this.onPageDefinitionNotFound) {
      this.onPageDefinitionNotFound(page);
    }

    return Promise.reject(new Error(`Definition file for page '${page}' not found.`));
  }
}
