import CellsComponent from './component';

/**
 * Class for managing templates
 *
 * A template is a component used in Cells apps for managing the components distribution
 *
 * A template has defined zones like app__header or app__main
 * where you can place your components.
 * The property zoneId (in the json page definition file) indicates the zone in wHich the
 * the component is appended.
 *
 * The components added to the template are the template's children. There are two kind of children:
 * the fixed children and the normal children. The normal ones are appended
 * to the asigned zone, the fixed ones are just stored in the fixedChildren property
 * and appended to an especial zone that is fixed.
 *
 * As the class extends from Component it has properties like node which refers to
 * the node the template is attached.
 *
 * The 'state' atributte in the node is used to know the actual state of the template
 * it can be active, cached or inactive.
 *  @class CellsBridgeTemplate
 */
export default class CellsBridgeTemplate extends CellsComponent {
  /**
   * children
   *
   * a list of all the components in the template (including the fixed ones)
   *
   * @type {Array}
   */
  children = [];
  /**
   * fixedChildren
   *
   * a list of the components in the template of type fixed, not attached to the template
   * but in a special fixed zone
   *
   * @type {Array}
   */
  fixedChildren = [];
  /**
   * type
   *
   * indicates the type of this component
   *
   * @type {String}
   */
  type = 'TEMPLATE';

  /**
   * returns the zone node in the template identified by the id.
   *
   * @param {String} zoneId
   *
   * @return {HTMLElement}
   */
  getZone(zoneId) {
    var curTemplate = this.node;
    var node;
    if (!zoneId) {
      node = curTemplate;
    } else if (curTemplate.$ && curTemplate.$[zoneId]) {
      node = curTemplate.$[zoneId];
    } else {
      node = curTemplate.querySelector('#' + zoneId);
    }
    return node || curTemplate;
  }

  /**
   * sets the attribute cache in the template node to 'cached'
   * for not loading the template again the next time the template is used
   */
  cache() {
    this._setAttribute('state', 'cached');
  }

  /**
   * sets the attribute cache in the template node to 'active'
   * so you can know which of the templates in html is the actual one
   */
  activate() {
    this._setAttribute('state', 'active');
  }

  /**
   * sets the attribute cache in the template node to 'inactive'
   * so you can know which of the templates in html are not the actual one
   */
  deactivate() {
    this._setAttribute('state', 'inactive');
  }

  native() {
    this._setAttribute('state', 'native');
  }

  resetNextNavigation() {
    this._getTemplate(this.node)._nextNavigation = undefined;
  }

  /**
   * Set given value to corresponding attribute name of current template.
   *
   * @private
   * @method _setAttribute
   * @param {String} name  Attribute name.
   * @param {String} value Attribute value.
   */
  _setAttribute(name, value) {
    const template = this._getTemplate(this.node);

    try {
      template.setAttribute(name, value);
    } catch(err) {
      throw new Error(`${this.node.tagName.toLowerCase()} has no valid template. Template was ${template}`);
    }
  }

  /**
   * Get given attribute value from the current template.
   *
   * @private
   * @method _setAttribute
   * @param {String} name  Attribute name.
   * @return {String} Attribute value.
   */
  _getAttribute(name) {
    const template = this._getTemplate(this.node);
    let attribute = '';

    try {
      attribute = template.getAttribute(name);
    } catch (err) {
      throw new Error(`${this.node.tagName.toLowerCase()} has no valid template. Template was ${template}`);
    }

    return attribute;
  }

  /**
   * Returns current template based on node type.
   * If it's a routable component (page), we retrieve the first child element that matches with cells-template.
   * Otherwise, we return directly the node (cells-template).
   *
   * @private
   * @method _getTemplate
   * @param  {HTMLElement} node Node for template retrieval.
   * @return {HTMLElement}      Associate template from given node.
   */
  _getTemplate(node) {
    const { tagName } = node;
    const isPage = tagName.toLowerCase().endsWith('-page');

    return isPage ? this._getCellsTemplateFromPage(node) : node;
  }

  /**
   * Returns first element from shadowRoot child nodes that matchs 'cells-template'.
   *
   * @private
   * @method _getCellsTemplateFromShadowRootChildNodes
   * @param  {HTMLElement} node First level component that contains cells-template inside shadowRoot childNodes.
   * @return {HTMLElement}      Cells template.
   */
  _getCellsTemplateFromPage(node) {
    const list = (node.shadowRoot && node.shadowRoot.childNodes) ? node.shadowRoot.childNodes : node.children;
    return Array.from(list)
      .find(el => el && el.tagName && (el.tagName.toLowerCase().indexOf('cells-template') !== -1 || el.getAttribute('data-cells-type')==='template' ));

  }

  _getContainerAndSlot(zone) {
    let container = zone;
    let slot = zone;
    if (zone) {
      let parts = zone.split('.');
      if (parts.length===2) {
        container = parts[0];
        slot = parts[1];
      }
    }
    return { container,  slot };
  }

  /**
   * recieves one component or a list of them, gets the new ones and append
   * them as child in the selected zone of the template
   *
   * @param {Array}/{Object} components
   *
   * @return {Boolean}
   */
  append(components) {
    /* istanbul ignore else */
    if (!components) {
      return;
    }
    /* istanbul ignore else */
    if (components.length === undefined) {
      components = [components];
    }

    let newContentComponents = components.filter(component => component.fixed !== true);
    let newFixedChildren = components.filter(component => component.fixed === true);
    this.fixedChildren = this.fixedChildren.concat(newFixedChildren);
    this.children = this.children.concat(components);
    let zoneCache = [];

    for (let index = 0; index < newContentComponents.length; index++) {
      const component = newContentComponents[index];
      let { container, slot } = this._getContainerAndSlot(component.zone);

      component.node.setAttribute('data-select', slot);
      // for the use of slots required by Polymer 2
      component.node.setAttribute('slot', slot);
      if (typeof zoneCache[container] !== 'object') {
        zoneCache[container] = this.getZone(container);
      }
      zoneCache[container].appendChild(component.node);
    }
  }
  
  /**
   * @param {Object} cellsTemplateConfig
   */
  config(cellsTemplateConfig) {
    const {
      name,
      template: { id: templateId, name: templateName }
    } = cellsTemplateConfig;

    this.name = name;
    this.node.id = templateId;
    this.node.name = templateName;

    this.setProps();
    this.setAttrs();
  }
}
