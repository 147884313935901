// BUILD_TARGET import statement is replaced by NormalModuleReplacementPlugin at build time with desireed target.
// Possible values: core, native, polymer.
import './polyfills';
import Bridge from './BUILD_TARGET/bridge';
import * as metadata from './core/constants';

// TARGETS and BUILD_TARGET are injected through Webpack DefinePlugin,
const TARGET_NAMESPACE = TARGETS[BUILD_TARGET] || TARGETS['core'];
const { windowExport } = TARGET_NAMESPACE;

// We expose Bridge to Browsers
window[windowExport] = Bridge;
