// Taken from string.js npm package
// https://github.com/jprichardson/string.js

export const dasherize = str => str.trim().replace(/[_\s]+/g, '-').replace(/([A-Z])/g, '-$1').replace(/-+/g, '-').toLowerCase()
export const camelize = str => str.trim().replace(/(-|_|\s)+(.)?/g, (mathc, sep, c) => c ? c.toUpperCase() : '');
export const isPlainObject = obj => Object.prototype.toString.call(obj) === '[object Object]';
export const findPropertyInArray = prop => arr => arr
  .map(findProperty(prop))
  .flat();
export const findPropertyInObject = prop => (collection) => Object
  .entries(collection)
  .map(([k, v]) => (k === prop) ? [v] : findProperty(prop)(v))
  .flat();
export const findProperty = prop => v => isPlainObject(v)
  ? findPropertyInObject(prop)(v)
  : Array.isArray(v)
    ? findPropertyInArray(prop)(v)
    : [];
export const attributeWhiteList = [
      'ambient',
      'variant',
      'disabled'
    ];
export const setAttribute = (node, attr, value) => {
  if (typeof(value)==='boolean') {
    if (value) {
    node.setAttribute(attr, '');
    } else {
    node.removeAttribute(attr);
    }
  } else {
    node.setAttribute(attr, value);
  }
};
export default {
  dasherize,
  camelize,
  findProperty,
  attributeWhiteList,
  setAttribute
};
