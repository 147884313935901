
import { BRIDGE_CHANNEL_KEY, BRIDGE_BIND_KEY, lastValue } from './feature-flag-collection';

export function defaultChangedCallback(fn) {
  if(typeof fn === 'function') {
    _defaultCallbackElement = fn;
  }
}

export function extend(cellsComponent, bridge) {
  let extraConnections = _defaultConnections();
  let spec = cellsComponent.spec;
  let nodeFFCallback = cellsComponent.node[BRIDGE_BIND_KEY] || _defaultCallbackElement;
  Object.defineProperty(cellsComponent.node, BRIDGE_BIND_KEY, {
    value: () => _nodeFFCallbackWrap(cellsComponent, bridge, nodeFFCallback)
  });
  // update connections with new connection of FeatureFlag
  if(spec.connections && spec.connections.in) {
    Object.assign(spec.connections.in, extraConnections.in)
  } else if(spec.connections) {
    Object.assign(spec.connections, extraConnections)
  } else {
    spec.connections = extraConnections;
  }

  // set last value in collection. These is used in "ComponentConnector" when publish in output channel from events
  lastValue.set(cellsComponent.node, cellsComponent.node[BRIDGE_BIND_KEY]());

  return cellsComponent;
}

export default { extend, defaultChangedCallback, BRIDGE_CHANNEL_KEY };

// - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - 
// . . . . .. . . . . .. . . . .Private. . . . .. . . . .  . . . . . . .
// - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - 

let _defaultCallbackElement = _nodeFFCalback_toggleVisibility;

function _defaultConnections () {
  return {
    in : {
      [BRIDGE_CHANNEL_KEY]: {
        bind: BRIDGE_BIND_KEY
      }
    }
  }
};

function _nodeFFCalback_toggleVisibility(fFValue, cellsComponent) {
  cellsComponent.node.style.visibility = fFValue ? 'initial' : 'hidden';
  return !!fFValue;
}

function _nodeFFCallbackWrap(cellsComponent, {featureFlagCollection, ComponentConnector}, fFCallback) {
  const _ffValue = featureFlagCollection.value(cellsComponent.spec.featureFlag);
  const _ffValueResult = fFCallback.call(cellsComponent.node, _ffValue, cellsComponent) === false ? false : true;
  const _connections = cellsComponent.spec.connections


  if(_ffValueResult) {
    ComponentConnector._updateInConnections(cellsComponent.node, _connections.in)
  } else if(ComponentConnector.subscriptors.has(cellsComponent.node)){
    let inConnections = Object.keys(_connections.in)
      .filter(k => k !== BRIDGE_CHANNEL_KEY);
    ComponentConnector.unsubscribe(inConnections, cellsComponent.node);
  }

  // set last value in collection. These is used in "ComponentConnector" when publish in output channel from events
  lastValue.set(cellsComponent.node, _ffValueResult);

  return _ffValueResult
}
