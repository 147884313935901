import PolymerAdapter from './polymer-adapter';
import LitElementAdapter from './lit-element-adapter';
import VanillaAdapter from './vanilla-adapter';

const POLYMER_ADAPTER = 0;
const LIT_ADAPTER = 1;
const VANILLA_ADAPTER = 2;

export default class AdapterResolver {
  constructor(componentConnector) {
    this.adapters = new Array(2);
    this.adapters[POLYMER_ADAPTER] = new PolymerAdapter(componentConnector);
    this.adapters[LIT_ADAPTER] = new LitElementAdapter(componentConnector);
    this.adapters[VANILLA_ADAPTER] = new VanillaAdapter(componentConnector);
  }

  /**
   * node: {HTMLElement}
   */
  getNodeAdapter(node) {
    return this.adapters.find(adapter => adapter.isInstance(node)) || this.adapters[POLYMER_ADAPTER];
  }

  getAdapterByName(name) {
    let index;
    switch (name) {
    case 'polymer' :
      index = POLYMER_ADAPTER;
      break;
    case 'litElement' :
      index = LIT_ADAPTER;
      break;
    case 'vanilla' :
      index = VANILLA_ADAPTER;
      break;
    default:
      index = POLYMER_ADAPTER;
    }
    return this.adapters[index];
  }

  getComponentAdapter(component) {
    const render = component.spec && component.spec.render;
    return this.getAdapterByName(render);
  }
}