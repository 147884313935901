import CellsBridgeManagerDom from './manager/dom';

export default class CellsBridgeComponent extends CellsBridgeManagerDom {

  constructor(spec) {
    super();
    this.spec = spec;
    this.spec.node = this.spec.node ? this.spec.node : this.createElement(this.spec.tagName);
  }

  setProps() {
    var spec = this.spec;
    if (spec.connections && spec.connections.ignoreAttr) {
      for (let index = 0; index < spec.connections.ignoreAttr.length; index++) {
        const key = spec.connections.ignoreAttr[index];
        delete spec.properties[key];
      }
    }

    super.setProps(this.node, spec.properties, spec.render);
    this.setAttrs();
  }

  setAttrs() {
    super.setAttrs(this.node, this.spec.attributes);
  }

  get type() {
    return this.spec.type;
  }

  set type(type) {
    this.spec.type = type;
  }

  get zone() {
    return this.spec.zone;
  }

  set zone(zone) {
    this.spec.zone = zone;
  }

  get fixed() {
    return this.spec.fixed;
  }

  set fixed(fixed) {
    this.spec.fixed = fixed;
  }

  get container() {
    return this.spec.container;
  }

  set container(container) {
    this.spec.container = container;
  }

  get priority() {
    return this.spec.priority;
  }

  set priority(priority) {
    this.spec.priority = priority;
  }

  get node() {
    return this.spec.node;
  }

  set node(node) {
    this.spec.node = node;
  }

  get connections() {
    return this.spec.connections;
  }

  set connections(connections) {
    this.spec.connections = connections;
  }
}
