import {dasherize} from './utils';

/**
 * Get path for component retrieval based on component definition properties.
 *
 * @param  {Object} componentDefinition Raw component definition.
 * @return {String}                     Path for component retrieval.
 */
const getPathFromComponent = componentDefinition => {
  const { familyPath, tag, tagName } = componentDefinition;
  const _tag = tag || tagName;
  const firstPath = familyPath || _tag;

  return `${firstPath}/${_tag}.html`;
};

export default class CellsBridgeComposerSanitizer {
  _buildConfig(component, type = 'UI') {
    const { type: componentType, tag, tagName, zone, fixed, container, priority, properties, render, featureFlag, attributes } = component;
    const path = getPathFromComponent(component);
    const configType = componentType ? componentType.toUpperCase() : type;
    const config = {
      type: configType,
      tagName: tag || tagName,
      zone,
      fixed,
      container,
      priority,
      properties: properties || {},
      attributes: attributes || {},
      path,
      render,
      featureFlag
    };

    return this._parseConnections(config);
  }

  parse(json) {
    const LTRIM_SLASH = /^\/(\b)/;
    const components = this._parseComponentsFromJson(json, 'components', 'UI');
    let jsonTemplate = json.template;
    if (jsonTemplate) {
      jsonTemplate = this._buildConfig(jsonTemplate, 'TEMPLATE');
    }
    let pages = {};
    let jsonPages = json.pages;
    /* istanbul ignore if */
    if (jsonPages) {
      Object.entries(jsonPages).forEach(([page, {url}]) => {
        pages[page] = '/' + url.replace(LTRIM_SLASH, '');
      });
    }
    return {
      page: json.page,
      currentPage: json.currentPage || {},
      template: jsonTemplate,
      components: components,
      pages: pages
    };
  }

  _parseComponentsFromJson(json, key, type) {
    const components = Array.isArray(json[key]) ? json[key] : [];
    return components.map(component => this._buildConfig(component, type));
  }

  /**
   * Extend given component definition object with parsed component connections.
   *
   * @private
   * @method _parseConnections
   * @param  {Object} rawComponentDefinition  Raw component definition.
   * @return {Object}                         Component definition including connections.
   */
  _parseConnections(rawComponentDefinition) {
    const componentDefinition = this._normalizeComponentDefinition(rawComponentDefinition);
    const { properties: { cellsConnections } } = componentDefinition;

    if (!cellsConnections || !Object.keys(cellsConnections).length > 0) {
      return componentDefinition;
    }

    const componentDefinitionWithConnections = Object.assign({}, componentDefinition);

    if (cellsConnections.params) {
      const params = this._parseConnectionsParams(cellsConnections.params);

      /* istanbul ignore if */
      if (!cellsConnections.in) {
        cellsConnections.in = {};
      }

      /* istanbul ignore if */
      if (!cellsConnections.out) {
        cellsConnections.out = {};
      }

      Object.assign(cellsConnections.in, params.in);
      Object.assign(cellsConnections.out, params.out);
    }

    componentDefinitionWithConnections.connections = cellsConnections;

    return componentDefinitionWithConnections;
  }

  /**
   * Normalizes given raw component definition. Safe-guard method.
   *
   * @private
   * @method _normalizeComponentDefinition
   * @param  {Object} rawComponentDefinition Raw component definition object.
   * @return {Object}                        Normalized component definition object.
   */
  _normalizeComponentDefinition(rawComponentDefinition) {
    return Object.assign({
      properties: { cellsConnections: { in: {}, out: {} } } // default
    }, rawComponentDefinition);
  }

  /**
   * Parse params to appropiate connections.
   *
   * @private
   * @method _parseConnectionsParams
   * @param  {Object} params Params to be parsed.
   * @return {Object}        Parsed connections.
   */
  _parseConnectionsParams(params) {
    const connections = {
      in: {},
      out: {}
    };

    for (const prop in params) {
      if(params.hasOwnProperty(prop)){
        const paramDef = params[prop];
        const outBind = dasherize(prop) + '-changed';

        connections.in[paramDef] = this._createConnection(prop);
        connections.out[paramDef] = this._createConnection(outBind);
      }
    }

    return connections;
  }

  /**
   * Create connection object based on given parameters.
   *
   * @private
   * @method _createConnection
   * @param  {String}  bind          Property to bind to.
   * @param  {Boolean} [global=true] Global flag.
   * @return {Object}                Connection object.
   */
  _createConnection(bind, global = true) {
    return {
      bind,
      global
    };
  }

  split(data) {
    var response;
    /* istanbul ignore if */
    if (data) {
      response = {
        CC: [],
        UI: [],
        DM: []
      };
      for (let index = 0; index < data.length; index++) {
        const item = data[index];
        response[item.type].push(item);
      }
    }
    return response;
  }
}
