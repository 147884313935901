import eventManager from './events';
import Constants from '../constants';

const { externalEventsCodes } = Constants;

var _after = function (times, func) {
  return function () {
    if (--times < 1) {
      return func();
    }
  };
};

let CellsManagerImport = class CellsManagerImport {
  _importElement(endPoint, componentPath, callback) {
    var node = document.createElement('link');
    node.rel = 'import';
    node.href = endPoint + componentPath;
    node.onload = callback;
    node.onerror = callback;
    node.setAttribute('async', '');

    document.head.appendChild(node);
  }

  _importElementPromise(endPoint, componentPath) {
    return new Promise(resolve => {
      this._importElement(endPoint, componentPath, resolve);
    });
  }

  loadBundleForTemplate(baseUri, pageName, callback) {
    return new Promise((resolve) => {
      const finishComponentLoading = () => {
        if (callback) {
          callback.call(this);
        }

        resolve();
      };

      this._importElement(baseUri, pageName + '.html', finishComponentLoading);
    });
  }

  loadComponent(component, baseUri, callback) {
    const { BEFORE_IMPORT, AFTER_IMPORT } = externalEventsCodes;

    return new Promise((resolve) => {
      const finishComponentLoading = () => {
        if (callback) {
          callback.call(this);
        }

        resolve();
      };

      var list = component;

      if (!Array.isArray(list)) {
        list = [component];
      }

      if (list.length === 0) {
        finishComponentLoading();
        return;
      }

      eventManager.emit(BEFORE_IMPORT);

      var iCb = _after(list.length, () => {
        eventManager.emit(AFTER_IMPORT);
        finishComponentLoading();
      });

      for (let index = 0; index < list.length; index++) {
        const item = list[index];
        this._importElement(baseUri, item.spec.path, iCb);
      }
    });
  }

  loadComponentByPriority(component, baseUri, callback) {
    const { BEFORE_IMPORT, AFTER_IMPORT } = externalEventsCodes;

    let list = component;
    if (!Array.isArray(list)) {
      list = [component];
    }

    if (list.length === 0) {
      if (callback) {
        callback.call(this);
      }
      return;
    }

    let priorityLists = this._createPriorityLists(list);

    eventManager.emit(BEFORE_IMPORT);

    this._chainElementLoading(baseUri, priorityLists, 0).then(() => {
      eventManager.emit(AFTER_IMPORT);
      if (callback) {
        callback.call(this);
      }
    });
  }

  _createSortedArratByPriorityAndUniqueElements(list) {
    list.sort((a, b) => {
      let priorityA = (a.priority || 1000);
      let priorityB = (b.priority || 1000);
      if (priorityA === priorityB) {
        return a.spec.path <= b.spec.path ? -1 : 1
      } else {
        return priorityA - priorityB;
      }
    });
    return list.filter((elem, pos, arr) => {
      return pos === 0 || elem.spec.path !== arr[pos - 1].spec.path;
    });
  }

  _createPriorityLists(list) {
    let sortedList = this._createSortedArratByPriorityAndUniqueElements(list);
    let currentPriority = sortedList[0].priority;
    let priorityLists = [[]];
    let pivote = 0;
    for (let index = 0; index < sortedList.length; index++) {
      const item = sortedList[index];
      if (item.priority === currentPriority) {
        priorityLists[pivote].push(item);
      } else {
        currentPriority = item.priority;
        priorityLists.push([]);
        pivote = priorityLists.length - 1;
        priorityLists[pivote].push(item);
      }
    }
    return priorityLists;
  }

  _chainElementLoading(baseUri, elementPriorityLists, index) {
    const currentPromises = [];

    if (elementPriorityLists.length === index) {
      return Promise.resolve();
    }

    for (let j = 0; j < elementPriorityLists[index].length; j++) {
      currentPromises.push(this._importElementPromise(baseUri, elementPriorityLists[index][j].spec.path));
    }

    const count = index + 1; // OJO!!! Si no creamos variable intermedia / scoped, y hacemos count++, siempre cogerá el valor inicial.

    return Promise.all(currentPromises).then(() => this._chainElementLoading(baseUri, elementPriorityLists, count));
  }
}

export default CellsManagerImport;
