class InMemmoryStorage  {
  dictionary = {};
  getItem(key) {
    return this.dictionary[key] || null;
  }
  setItem(key, value) {
    this.dictionary[key] = value;
  }
}

export default class CellsStorage {
  prefix = '';
  persistent = false;
  internalStorage = new InMemmoryStorage();

  constructor(options) {
    Object.assign(this, options);

    if (this.persistent) {
      this.clear();
    }
  }

  get storage() {
    let store;
    try {
      store = this.persistent ? window.localStorage : window.sessionStorage;
      store.setItem('_$_', {});
    } catch (error) {
      store = this.internalStorage;
    }
    return store;
  }

  getItem(key) {
    return JSON.parse(this.storage.getItem(this.prefix + key));
  }

  setItem(key, value) {
    this.storage.setItem(this.prefix + key, JSON.stringify(value, '', true));
  }

  clear() {
    var pattern = new RegExp('^(' + this.prefix + ')');

    for (let key in this.storage) {
      if(this.storage.hasOwnProperty(key)){
        if (pattern.test(key)) {
          this.storage.removeItem(key)
        }
      }
    }
  }
}
