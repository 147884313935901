export default class CellsBridgeConnector {

  _get(options, format) {
    return new Promise(
      function(resolve, reject) {
        var xhr = new XMLHttpRequest();
        if (!options || !options.url) {
          reject(new Error('URL not defined'));
          return;
        }
        var method = (options.method || '').toUpperCase() || 'GET';
        xhr.addEventListener('load', (e) => {
          let xhr = e.target;
          let status = xhr.status;
          if ((status >= 200 && status < 300) || (status == 0 && xhr.responseText.length > 0)) {
            if (format==='json') {
              resolve(JSON.parse(xhr.response));
            } else {
              resolve(xhr.responseText);
            }
          } else {
            reject(new Error(xhr.statusText));
          }
        });
        xhr.addEventListener('error', () => {
          reject(new Error('Network Error'));
        });
        xhr.open(method, options.url);
        //
        let headers = options.headers;
        for (var header in headers) {
          if (headers.hasOwnProperty(header)) {
            xhr.setRequestHeader(header, headers[header]);
          }
        }
        xhr.send(options.body);
      }
    );
  }

  getJSON(options) {
    return this._get(options, 'json');
  }

  getHTML(options) {
    return this._get(options, 'html');
  }
}
