const _dashToCamelCase = input => {
  return input.toLowerCase().replace(/-(.)/g, function(match, group1) {
    return group1.toUpperCase();
  });
}

export default class PolymerAdapter {

  constructor(componentConnector) {
    this.componentConnector = componentConnector;
  }

  isUnresolved(node) {
    const isCustomElement = node.tagName.includes('-');
    const isPolymerElement = this._isPolymerElement(node);
    return isCustomElement && !isPolymerElement;
  }

  isInstance(node) {
    const isCustomElement = node.tagName.includes('-');
    return isCustomElement && !this.isUnresolved(node);
  }

  /**
   * Returns true if the event has reached the node that is listening the event
   *
   * @param {Event} event
   */
  isEventAtTarget(event) {
    let atTarget;
    if (/** For Polymer 2 **/ Polymer && Polymer.Settings && ( Polymer.Settings.hasShadow === false || /** For Polymer 2 **/ Polymer.Settings.useShadow === false) ) {
      const normalizedEvent = Polymer.dom(event);
      atTarget = normalizedEvent.localTarget === event.currentTarget;
    } else {
      const AT_TARGET_VALUE = Event.AT_TARGET || Event.prototype.AT_TARGET;
      atTarget = event.eventPhase === AT_TARGET_VALUE;
    }
    return atTarget;
  }

  dispatchActionFunction(evt, target, method) {
    var propertyName = this._getPropertyChangedName(evt.type);
    var payload;

    if (propertyName && evt.detail && evt.detail.hasOwnProperty('value')) {
      if (Polymer && Polymer.dom(evt).rootTarget) {
        payload = Polymer.dom(evt).rootTarget[propertyName];
      } else {
        payload = evt.detail.value;
      }
    } else {
      payload = evt.detail;
    }

    if (typeof(method) === 'function') {
      method(payload);
    } else {
      target[method](payload);
    }
  }

  dispatchActionProperty(evt, target, property) {
    var info;
    var data = this._parseActionInEvent(evt, property, target);

    if (target && target.constructor._finalize) {
      var isProperty = target.constructor.properties[data.property];

      if (isProperty) {
        target[data.property] = data.value;
        return;
      } else {
        return this._handleAttribute(target, data);
      }
    }

    //check if target is an instance of Polymer Element to check if is a polymer 2 element
    if (target && target.getPropertyInfo || Polymer.Element !== undefined && (target instanceof Polymer.Element) === false) {
      if (target.getPropertyInfo) {
        info = target.getPropertyInfo(data.property)
      } else {
        info = this._getTargetProperties(target)[data.property];
      }

      /* istanbul ignore else */
      if (info && info.defined && !info.readOnly && !info.computed) {
        return target.set(data.path, data.value);
      }
    }
    //check if target is an instance of Polymer Element to check if is a polymer 2 element
    if (target && target.getPropertyInfo === undefined || Polymer.Element !== undefined && (target instanceof Polymer.Element) === false) {
      info = this._getTargetProperties(target) !== undefined ? this._getTargetProperties(target)[data.property] : undefined;

      if (info && !info.readOnly && !info.computed) {
        return target.set(data.path, data.value);
      }

    }

    return this._handleAttribute(target, data);
  }

  processFirstTimeConnections(node, connections) {

    if (!node || !connections) {
      return;
    }

    if (node.__isCellsConnected) {
      return;
    }

    if (connections.in) {
      for (let action in connections.in) {
        if(connections.in.hasOwnProperty(action)){
          if (this.componentConnector.manager.get(action)._events[0]) {
            if (connections.ignoreAttr) {
              connections.ignoreAttr.push(connections.in[action].bind);
            } else {
              connections.ignoreAttr = [connections.in[action].bind];
            }
          }
        }
      }
    }

    // iterate over all out channels associated to
    // a change property event and force the event triggering
    // for the first time the component is connected to a channel
    if (connections.out) {
      for (let _action in connections.out) {
        if(connections.out.hasOwnProperty(_action)){
          let attrName = this._getPropertyChangedName(connections.out[_action].bind);

          let polymerVersion = this._whichPolymerVersion(node);
          if (polymerVersion === 2 ) {
            if (attrName && node.__data && !this.componentConnector.manager.get(_action)._events[0]) {
              if (node && node._hasReadOnlyEffect) {
                if (!node._hasReadOnlyEffect(attrName) && !node._hasComputedEffect(attrName)) {
                  let oldValue = node[attrName];
                  node.__data[attrName] = undefined;
                  node[attrName] = oldValue;
                }
              }
            }
          } else {
            if (attrName && node.__data__ && !this.componentConnector.get(_action)._events[0]) {
              if (node && node.getPropertyInfo) {
                var info = node.getPropertyInfo(attrName);

                if (info.defined && !info.readOnly && !info.computed) {
                  let oldValue = node[attrName];
                  // remove polymer internal current value to trigger model changes
                  node.__data__[attrName] = undefined;
                  node[attrName] = oldValue;
                }
              }
            }
          }
        }
      }
    }

    node.__isCellsConnected = true;
  }

  /**
   * Return the version of Polymer instance if the node is a Polymer element.
   * Return -1 if if the node is not a Polymer element.
   *
   * @param {Element} node
   */
  _whichPolymerVersion(node) {
    let version = -1;
    const Noop = function(){};
    const {Element = Noop, ElementMixin = Noop, LegacyElementMixin = Noop} = window.Polymer || {};
    const isPolymer1 = node.__isPolymerInstance__ === true;
    const isPolymer2 = (
      (node instanceof Element) ||
      (node instanceof ElementMixin) ||
      (node instanceof LegacyElementMixin) ||
      (node.is !== undefined && node.ready !== undefined && node.notifyPath !== undefined)
    );

    if (isPolymer2) {
      version = 2;
    } else if(isPolymer1) {
      version = 1;
    }
    return version;
  }

  /**
   * Return true if the node is instance of a Polymer element, otherwise return false.
   *
   * @param {Element} node
   */
  _isPolymerElement(node) {
    return this._whichPolymerVersion(node) > 0;
  }

  _getTargetProperties(target) {
    let props;

    if (target.constructor.config) {
      props = target.constructor.config.properties;
    } else if (target.constructor._properties) {
      props = target.constructor._properties
    }

    return props;
  }

  _handleAttribute(target, {value, path}) {
    // check if target property is a boolean one and current value is false.
    // it expects to remove the attribute (as it's a boolean one)
    if (typeof(value) === 'boolean' && !value) {
      return target.removeAttribute(path);
    }

    return target.setAttribute(path, value);
  }

  _getPropertyChangedName(name) {
    let propertyName;
    const EVENT_CHANGED = '-changed';

    if (name.indexOf(EVENT_CHANGED, name.length - EVENT_CHANGED.length) !== -1) {
      propertyName = name.slice(0, - EVENT_CHANGED.length);
      propertyName = _dashToCamelCase(propertyName);
    }

    return propertyName;
  }

  _parseActionInEvent(evt, targetPath, target) {
    let path;
    let value;

    // Check if event is because of property changed.
    const propertyName = this._getPropertyChangedName(evt.type);

    if (propertyName && evt.detail && evt.detail.hasOwnProperty('value')) {
      value = evt.detail.value;
      targetPath = targetPath || propertyName;

      if (evt.detail.path) {
        path = evt.detail.path.replace(propertyName, targetPath);
      } else {
        path = targetPath;
      }
    } else {
      path = targetPath;
      value = evt.detail;
    }

    if (target !== undefined && target.is === undefined && typeof(value) === 'object') {
      if (Polymer && Polymer.Element === undefined) {
        value = JSON.stringify(value);
      } else {
        /* istanbul ignore else */
        if ((Polymer && target instanceof Polymer.Element) === false) {
          value = JSON.stringify(value);
        }
      }
    }

    return {
      path: path,
      value: value,
      property: targetPath
    };
  }

}