import CellsStorage from './storage';

const CONFIG_STORAGE_PREFIX = '__app_';
const CONFIG_STORAGE_KEY = 'config';

let ApplicationConfigManager = class ApplicationConfigManager {
  constructor({ ActionChannelManager }) {
    this.ActionChannelManager = ActionChannelManager;
    this.storage = this._getAppConfigStorage();
  }

  _getAppConfigStorage() {  
    return new CellsStorage({
      prefix: CONFIG_STORAGE_PREFIX,
      persistent: false,
    });
  }

  saveAppConfig(config) {
    const store = {};
  
    for (let prop in config) {
      if (config.hasOwnProperty(prop) && this.ActionChannelManager.isAllowedProperty(prop)) {
        store[prop] = config[prop];
      }
    }
  
    if (Object.keys(store).length > 0) {
      this.storage.setItem(CONFIG_STORAGE_KEY, store);
    }
  }
    
  loadAppConfig() {
    const config = this.storage.getItem(CONFIG_STORAGE_KEY);
  
    for (let prop in config) {
      config.hasOwnProperty(prop) && this.ActionChannelManager.updateProperty(prop, config[prop]);
    }
  }
}

export default ApplicationConfigManager;