import SEMaaS from 'cells-semaas-adapter';
import Constants from './constants';
import dotProp from 'es5-dot-prop';

const { get, set } = dotProp;
const {
  monitoring: {
    SEMAAS: {
      mrId, nameSpace, identifier, consumerId, policy, tsecStorageKey,
    },
  },
  externalEventsCodes
} = Constants;

// const flattenObject = (object, separator = '=', join = ', ') => Object.keys(object).map(key => `${key}${separator}${object[key]}`).join(join);

const {
  PAGE_READY,
  PARSE_ROUTE,
  AFTER_PUBLISH,
  NAV_REQUEST,
  BEFORE_SET_ATTR_TO_NODE,
  AFTER_SET_ATTR_TO_NODE,
  BEFORE_CREATE_NODE,
  AFTER_CREATE_NODE,
  BEFORE_IMPORT,
  AFTER_IMPORT,
  PAGE_REQUEST,
  PAGE_RESPONSE,
  DATA_LOAD,
  TEMPLATE_TRANSITION_END,
  TRACK_EVENT,
  TEMPLATE_REGISTERED,
  ROUTER_BACKSTEP,
  LOG_EVENT
} = externalEventsCodes;

export default class Monitoring {
  bridge = null;

  application = null;

  constructor(bridge) {
    const { semaas = {}, logs } = bridge;

    if (logs) {
      const monitorArchitecture = false; // Boolean(semaas && semaas.architecture);
      const applicationSemaasConfiguration = this.getApplicationConfiguration(semaas);

      this.application = this.initilizeSEMaaSAdapter(applicationSemaasConfiguration);

      if (monitorArchitecture) {
        const bridgeSemaasConfiguration = this.getBridgeConfiguration(semaas);

        this.bridge = this.initilizeSEMaaSAdapter(bridgeSemaasConfiguration);
      }
    }
  }

  async flush() {
    return this.application.flushAll();
  }

  getBridgeConfiguration(semaasConfiguration) {
    const { tsec2JWTEndpoint, region, dnsTld, version } = semaasConfiguration;

    return {
      policy,                     // bridge constant
      mrId,                       // bridge constant
      nameSpace,                  // bridge constant
      identifier,                 // bridge constant
      consumerId,                 // bridge constant
      tsec2JWTEndpoint,           // configuration object,
      region,                     // configuration object
      dnsTld,                     // configuration object
      version,                    // configuration object
    };
  }

  getApplicationConfiguration(semaasConfiguration) {
    const { tsec2JWTEndpoint, policy, mrId, nameSpace, consumerId, region, dnsTld, version, logLevel, tsecStorageKey =  'tsec' } = semaasConfiguration;
    return {
      policy,                     // configuration object
      mrId,                       // configuration object
      nameSpace,                  // configuration object
      identifier: 'application',  // application constant
      consumerId,                 // configuration object
      tsec2JWTEndpoint,           // configuration object
      region,                     // configuration object
      dnsTld,                     // configuration object
      version,                    // configuration object
      logLevel,                   // configuration object
      tsecStorageKey                 // configuration object
    };
  }

  initilizeSEMaaSAdapter(configuration) {
    return new SEMaaS(configuration);
  }

  logBridge(log) {
    this.bridge.log(log);
  }

  logApplication(log) {
    this.application.log(log);
  }

  ingest(spans) {
    this.application.ingest(spans);
  }

  createSpan(data) {
    return this.application && this.application.createSpan(data);
  }

  createUUID() {
    return this.application && this.application.createUUID();
  }

  hasBridgeLoggingEnabled() {
    return !!this.bridge;
  }

  hasApplicationLoggingEnabled() {
    return !!this.application;
  }

  buildLog(eventName, data) {
    const { detail } = data;
    const DEFAULT_LOG = {
      message: `cells-bridge::${eventName}`,
      properties: {}
    };
    const log = Object.assign({}, detail, DEFAULT_LOG);

    switch (eventName) {
    case PAGE_READY: {
      const { page, components } = data;

      log.properties = {
        page,
        components,
      };
      break;
    }
    case PARSE_ROUTE: {
      const { name, params, pattern } = data;
      //const normalizedParams = flattenObject(params);

      log.properties = {
        route: name,
        //params: normalizedParams,
        params,
        pattern,
      };
      break;
    }
    case NAV_REQUEST: {
      const { event, detail } = data;
      const { page } = detail;
      const params = event.detail;
      //const normalizedParams = flattenObject(params);

      //trace.description = `Page: ${page}, params: ${normalizedParams}`;
      log.properties = {
        page,
        params
      };
      break;
    }
    case PAGE_REQUEST: {
      const { params, url } = data;

      log.properties = {
        params,
        url
      };

      break;
    }
    case PAGE_RESPONSE: {
      log.properties = Object.assign({}, data);
      break;
    }
    // Special case.
    // We canalize the log message through the rest of bridge events - as we need to deflate data from channels
    case LOG_EVENT: {
      const { detail, event } = data;
      const { message, properties } = detail;
      const { detail: eventDetail } = event;

      log.message = message;
      log.properties = this.unflattenData(properties, eventDetail); // unflatten(properties, event)
      break;
    }
    case AFTER_PUBLISH:
    case BEFORE_SET_ATTR_TO_NODE:
    case AFTER_SET_ATTR_TO_NODE:
    case BEFORE_CREATE_NODE:
    case AFTER_CREATE_NODE:
    case BEFORE_IMPORT:
    case AFTER_IMPORT:
    case DATA_LOAD:
    case TEMPLATE_TRANSITION_END:
    case TRACK_EVENT:
    case TEMPLATE_REGISTERED:
    case ROUTER_BACKSTEP:
      break;
    }

    return log;
  }

  isBridgeLoggableEvent(eventName) {
    const LOGGABLE_EVENTS = [
      PAGE_READY,
      PARSE_ROUTE,
      NAV_REQUEST,
      PAGE_REQUEST,
      PAGE_RESPONSE,
    ];

    return LOGGABLE_EVENTS.includes(eventName);
  }

  unflattenData(properties, values) {
    const result = {};

    Object.keys(properties).forEach(key => {
      const property = properties[key];
      let value = null;

      if (property instanceof Object) {
        const { bind: valuePath } = property;

        value = get(values, valuePath);
      } else {
        value = property;
      }

      set(result, key, value);
    });

    return result;
  }
}
