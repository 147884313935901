import { fromEvent } from 'rxjs';
import { filter } from 'rxjs/operators';
import eventManager from './manager/events'
import { Subscriptor } from './state';
import Constants from './constants';
import AdapterResolver from './adapter/adapter-resolver';
import {lastValue as FeatureFlagCollection_lastValue} from './featureflag/feature-flag-collection';
import {ChannelManager} from './channel-manager.js'

/**
 * @typedef {import('./bridge.js')} CellsBridge
 * @typedef {import('./state/subscriptor')} Subscriptor
 */

/**
 * @constant externalEventsCodes
 * 
 */
const { externalEventsCodes } = Constants;

/**
 * 
 *  @class ComponentConnector
 */
export default class ComponentConnector {

  constructor(bridge) {
    this.adapterResolver = new AdapterResolver(this);
    this.manager = new ChannelManager();
    this.subscriptors = new Map();
    this.bridgeChannelsPrefix = /__bridge_(?!ch)/;
    this.bridge = bridge;
  }

  /**
   * get a subscriptor
   * 
   * @param  {HTMLElement} node
   * @returns {Subscriptor}
   */

  getSubscriptor(node) {
    var subscriptor = this.subscriptors.get(node);

    if(!subscriptor) {
      subscriptor = new Subscriptor(node);
      this.subscriptors.set(node, subscriptor);
    }

    return subscriptor;
  }

  /**
   * Register a component in pubsub
   *
   * @param  {HTMLElement}  node
   */
  registerComponent(component) {
    const {node, connections} = component;
    const adapter = this.adapterResolver.getComponentAdapter(component);

    if(node && connections) {
      this._registerOutConnections(node, connections.out);
      this._registerInConnections(node, connections.in);
    }
    adapter.processFirstTimeConnections(node, connections);
  }

  /**
   * Register new connections of a node that may have other connections registered previously.
   *
   * @param  {HTMLElement}  node
   * @param  {Object}       connections
   */
  progressiveRegisterConnections(node, connections) {
    if(node && connections) {
      this._registerOutConnections(node, connections.out);
      this._updateInConnections(node, connections.in);
    }
  }
  /**
   * Register new in connections of a node that may have other connections registered previously.
   *
   * @param  {HTMLElement}  node
   * @param  {Object}       connections
   */
  _registerInConnections(node, inConnections = {}) {
    Object.entries(inConnections).forEach(([channelName, {bind: bindName, previousState = false}])=> {
      this.addSubscription(channelName, node, bindName, previousState);
    });
  }
  /**
   * Add a subscription to a node with a channel
   *
   * @param  {channelName}  String
   * @param  {HTMLElement}  node
   * @param  {Object}       bind
   * @param  {Boolean}      previousState
   */
  addSubscription(channelName, node, bind, previousState = false) {
    const callback = this._wrapCallbackWithNode(node, bind);
    const channel = this.manager.get(channelName); // @TODO ojo!!! CC this.isBridgeChannel(channelName) ? this.manager.getUnsafe(channelName) : this.manager.get(channelName);

    if(channel) {
      const subscriptor = this.getSubscriptor(node);
      subscriptor.subscribe(channel, callback, previousState, bind);
    }
  }
  /**
   * update a connection of a node that may have other connections registered previously.
   *
   * @param  {HTMLElement}  node
   * @param  {Object}       connections
   */
  _updateInConnections(node, inConnections = {}) {
    Object.entries(inConnections).forEach(([channelName, {bind: bindName, previousState = false}])=> {
      this.updateSubscription(channelName, node, bindName, previousState);
    });
  }

  /**
   * Update a subscription to a node with a channel
   *
   * @param  {channelName}  String
   * @param  {HTMLElement}  node
   * @param  {Object}       bind
   * @param  {Boolean}      previousState
   */
  updateSubscription(channelName, node, bind, previousState = false) {
    const subscriptor = this.getSubscriptor(node);

    if(this.isActiveBridgeChannel(channelName) ||
      (!this.isActiveBridgeChannel(channelName) && !this.hasSubscriptions(subscriptor, channelName))
    ) {
      const channel = this.manager.get(channelName);
      const callback = this._wrapCallbackWithNode(node, bind);

      subscriptor.subscribe(channel, callback, previousState, bind);
    }
  }
  /**
   * Recover a node with a callback and a function
   *
   * @param  {HTMLElement}  node
   * @param  {String}       bindName
   * @returns {Function} returns a Callback function with a node attached
   */
  _wrapCallbackWithNode(node, bindName) {
    let cb = this.wrapCallback(node, bindName);
    cb.node = node;
    return cb;
  }
  /**
   * Recover a node with a callback and a function
   *
   * @param  {HTMLElement}  node
   * @param  {String}       bindName
   * @returns {Function}
   */
  wrapCallback(node, bindName) {
    // TODO: move to utils
    const _idleCallback = (fn) => {
      setTimeout(
        function () {
          if ('requestIdleCallback' in window) {
            window.requestIdleCallback(fn);
          } else {
            setTimeout(fn, 1);
          }
        }, 100
      );
    }
    return (evt) => {

      const adapter = this.adapterResolver.getNodeAdapter(node);
      const checkDispatchActionType = (mutations, observerObject) => {

        if (adapter.isUnresolved(node)) {
          _idleCallback(checkDispatchActionType);
        } else {
          if (typeof(bindName) === 'function' || typeof node[bindName] === 'function') {
            adapter.dispatchActionFunction(evt, node, bindName);
          } else {
            adapter.dispatchActionProperty(evt, node, bindName);
          }

          if (observerObject) {
            observerObject.disconnect();
          }
        }
      };

      if (adapter.isUnresolved(node)) {
        var observer = new MutationObserver(checkDispatchActionType);
        var config = { attributes: false, childList: true, characterData: true };
        observer.observe(node, config);

        _idleCallback(checkDispatchActionType, 100);
      } else {
        checkDispatchActionType();
      }
    };
  }
  /**
   * check if node has publisher
   *
   * @param  {Subscriptor}  publications
   * @param  {HTMLElement}  node
   * @param  {String}       channelName
   * @param  {String}       bindName
   * @returns {Boolean}
   */
  _hasPublisher({publications}, node, channelName, bindName) {
    return Boolean((publications._subscriptions || [])
      .find((publication) => (publication.node === node
        && publication.channelName === channelName
        && publication.eventName === bindName)));
  }
  /**
   * check if node has publisher
   *
   * @param  {HTMLElement}  node
   * @param  {Object}       connections
   */
  _registerOutConnections(node, outConnections = {}) {
    Object.entries(outConnections)
      .forEach(([channelName, conn])=> this.addPublication(channelName, node, conn.bind, conn));
  }
  /**
   * add a publicator in a node
   * 
   * @param  {String}       channelName
   * @param  {HTMLElement}  node
   * @param  {String}       bindName
   * @param  {Object}       outConnectionDefinition
   */
  addPublication(channelName, node, bindName, outConnectionDefinition) {
    if(this.isBridgeChannel(channelName)) {
      console.warn(`Forbidden operation. You are trying to write to a private channel (${channelName}).`);
    } else {
      let subscriptor = this.getSubscriptor(node);
      let hasPublisher = this._hasPublisher(subscriptor, node, channelName, bindName);

      if (!hasPublisher) {
        let channel = this.manager.get(channelName);
        subscriptor.publish(this.wrapEvent(node, bindName, channel, outConnectionDefinition));
      }
    }
  }
  /**
   * add a publicator in a node
   * 
   * @param  {String}       channelName
   * @param  {Object}       value
   */
  publish(channelName, value) {
    if(this.isBridgeChannel(channelName)) {
      console.warn(`Forbidden operation. You are trying to write to a private channel (${channelName}).`);
    } else {
      const channel = this.manager.get(channelName);
      const customEventName = `${channelName}-publish`;

      channel.next(new CustomEvent(customEventName, { detail: value }));
    }
  }
  /**
   * Unregister a node from pubsub
   * 
   * @param  {Array}  channels
   * @param  {HTMLElement}  node
   */
  unsubscribe(channels, node) {
    if(!channels || !node) {
      return;
    }

    const normalizedChannels = Array.isArray(channels) ? channels : [channels];
    const subscriptor = this.subscriptors.get(node);
    const byChannelName = (subscription) => normalizedChannels.includes(subscription.channel.name);
    const filterAndRemove = (sub) => (!(byChannelName(sub) && !sub.subscription.unsubscribe()));

    subscriptor.subscriptions = subscriptor.subscriptions.filter(filterAndRemove);
  }

  /**
   * Unregister a node from pubsub
   *
   * @param  {HTMLElement} node
   * @param  {Array} cleanPrivateChannels
   */
  unregisterComponent(node, cleanPrivateChannels) {
    if(!node) {
      return;
    }

    const subscriptor = this.subscriptors.get(node);

    if(subscriptor) {
      subscriptor.unsubscribe(cleanPrivateChannels);
      this.subscriptors.delete(node);
    }
  }
  /**
   * Unregister a node from pubsub
   *
   * @param  {Array} cleanPrivateChannels
   */
  unregisterAllSubscriptors(cleanPrivateChannels) {
    this.subscriptors.forEach((v) => {
      v.subscriptions.forEach(s => s.subscription.unsubscribe(cleanPrivateChannels));
    });
    this.subscriptors = new Map();
  }

  /**
   * Wrap an event.
   *
   * @param  {HTMLElement} node
   * @param  {String} eventName
   * @param  {Channel} channel
   *
   * @return {Function}
   */
  wrapEvent(node, eventName, channel, connection) {
    const { AFTER_PUBLISH, NAV_REQUEST, ROUTER_BACKSTEP, TRACK_EVENT, LOG_EVENT } = externalEventsCodes;
    const ffValue = (ff) => this.bridge.featureFlagCollection.value(Object.assign({defaultValue:true}, ff));

    var source = fromEvent(node, eventName);

    // this checking is for components with FeatureFlag configuration
    if(FeatureFlagCollection_lastValue.has(node)){
      source = source.pipe(filter(() => FeatureFlagCollection_lastValue.get(node)));
    }
    var wrappedListener = source.subscribe((event) => {
      let adapter = this.adapterResolver.getNodeAdapter(node);
      if(!adapter.isEventAtTarget(event)) {
        // If the event bubbles up from a child element:
        return;
      }

      channel.next(event);
      eventManager.emit(AFTER_PUBLISH, event);

      if(connection && connection.link && ffValue(connection.link.featureFlag)) {
        const linkObject = Object.assign({}, connection.link);

        if(connection.link.page){
          if(connection.link.page.hasOwnProperty('bind')){
            linkObject.page = event.detail[connection.link.page.bind]
          }
        }

        if(connection.link.cleanUntil){
          if(connection.link.cleanUntil.hasOwnProperty('bind')){
            linkObject.cleanUntil = event.detail[connection.link.cleanUntil.bind]
          }
        }

        eventManager.emit(NAV_REQUEST, {
          event: event,
          detail: linkObject
        });
      }

      if (connection && connection.backStep && ffValue(connection.backStep.featureFlag)) {
        eventManager.emit(ROUTER_BACKSTEP, {
          event: event,
          detail: {}
        });
      }

      if(connection && connection.analytics && ffValue(connection.analytics.featureFlag)) {
        eventManager.emit(TRACK_EVENT, {
          event: event,
          detail: connection.analytics
        });
      }

      if(connection && connection.log && ffValue(connection.log.featureFlag)) {
        eventManager.emit(LOG_EVENT, {
          event: event,
          detail: connection.log
        });
      }

      if (this.wrapEventHandler) {
        this.wrapEventHandler(event, connection, channel);
      }
    });

    wrappedListener.node = node;
    wrappedListener.eventName = eventName;
    wrappedListener.channelName = channel.name;
    wrappedListener.options = connection;

    return wrappedListener;
  }

  /**
   * receive a channel name and change old private values.
   *
   * @param  {string} name
   * @param  {object} value
   * @returns {Event}
   */
  createEvent(name, value) {
    let evt = new Event(name);
    evt.detail = { value };
    return evt;
  }

  /**
   * returns true if there's a private channel with the given name
   *
   * @param {String} name
   *
   * @return {Boolean}
   */
  isActiveBridgeChannel(name) {
    return this.isBridgeChannel(name) && this.manager.getUnsafe(name);
  }

  /**
   * returns true if the given name matches a private channel's name
   *
   * @param {String} name
   *
   * @return {Boolean}
   */
  isBridgeChannel(name) {
    return this.bridgeChannelsPrefix.test(name);
  }

  /**
   * returns true if the subscriptor has been subscribed to the given channel.
   *
   * @param {Subscriptor} subscriptor
   * @param {String} channelName
   *
   * @return {Boolean}
   */
  hasSubscriptions(subscriptor, channelName) {
    return Boolean(subscriptor.subscriptions.find(d => d.channel.name === channelName));
  }
}
