import EventEmitter from 'events';

const maxNumListeners = 20;

class CustomEventEmitter extends EventEmitter {
  /**
   * @param {HTMLElement} node
   * @param {string} name
   * @param {Function} callback
   */
  listenToOnce(node, name, callback) {
    node.addEventListener(name, function cb (event) {
      callback();
      event.currentTarget.removeEventListener(event.type, cb);
    });
  }
}

const eventManager = new CustomEventEmitter();

eventManager.setMaxListeners(maxNumListeners);

export default eventManager;


