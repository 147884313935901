import { ReplaySubject } from 'rxjs';

/**
 * @returns {String}
 */
function generateUUID() {
  let d = new Date().getTime();
  let uuid = 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function(c) {
    let r = (d + Math.random()*16)%16 | 0;
    return (c=='x' ? r : (r&0x3|0x8)).toString(16);
  });
  return uuid;
}
/**
 * @returns {ReplaySubject}
 */
const createChannel = () => {
  const channel = new ReplaySubject(1);

  const _nextFromSuperClass = channel.next.bind(channel);
  /**
   * @param {Object} value
   */
  channel.next = function(value) {
    if (!value.uuid) {
      value.uuid = generateUUID();
    }
    if (!value.time) {
      value.time = (new Date()).getTime();
    }
    _nextFromSuperClass(value);
  }
  /**
   * @returns  {Boolean}
   */
  channel.hasObservers = function() {
    if (this.closed) {
      throw new Error();
    }

    return this.observers.length > 0;
  }

  channel.complete = function() {};
  /**
   * @param  {any} error
   */
  channel.error = function(error) {
    this.observers.forEach(function(o) {
      o.isStopped = false;
      o.onError(error);
    });
  };
  /**
   * @param  {Number} index
   */
  channel.unsubscribe = function(index) {
    this.observers.splice(index, 1);
  };

  channel.unsubscribeAll = function() {
    this.observers.splice(0, this.observers.length);
  };

  channel.clean = function() {
    this._events = [];
    this.observers = [];
  };

  channel.dispose = function() {
    this.unsubscribe();
  };

  return channel;
}

export default createChannel;
